import React from "react";
import FullPageVideoBackground from "../components/VideoComponent";
import { Box, Grid } from "@mui/material";
import SignUpForm from "../../../shared/component/SignUpComponent";
import InfoOnlyTextComponent from "../../../shared/component/infoBlocks/onlyText";
import HeroMessageComponent from "../../../shared/component/heroMessage";
import TextImageInfoComponent from "../../../shared/component/infoBlocks/textImage";
import SingleColTextWithHeaderComponent from "../../../shared/component/infoBlocks/singleColTextWithHeader";
import SingleColTextNoHeaderComponent from "../../../shared/component/infoBlocks/singleColTextNoHeader";
import CardComponent from "../../../shared/component/CardComponent";

function HomePage() {
  return (
    <div>
      <Box sx={{ flexGrow: 1, height: "100vh", position: "relative" }}>
        {/* Background video or image */}
        <FullPageVideoBackground />
        {/* Overlay with tint */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        />
        <HeroMessageComponent message="Empowering Innovation, Enhancing Efficiency" />

        <Grid
          container
          spacing={2}
          sx={{ position: "relative", py: 4, px: 2, zIndex: 2 }}
        >
          <Grid item xs={0} md={1} />
          {/* Left side with header and message */}
          <Grid item xs={12} sm={12} md={5}>
            <InfoOnlyTextComponent
              header="Introducing Entity360"
              headerVariant={{ xs: "h4", sm: "h4", md: "h3", lg: "h3" }}
              message="Streamlined Workflows Meet Next-Gen AI: Transforming Business Operations for Peak Performance"
              messageVariant={{ xs: "body1", sm: "body1", md: "h4", lg: "h4" }}
              buttonText="Learn more..."
              buttonLink="/platform"
            />
          </Grid>
          {/* Right side with registration form */}
          <Grid item xs={12} md={4}>
            <SignUpForm header="Register for our 1-year Pilot Program" />
          </Grid>
          <Grid item xs={0} md={2} />
        </Grid>
      </Box>
      <Box>
        <Grid
          container
          spacing={20}
          sx={{ position: "relative", py: { xs: 25, sm: 5, md: 14 } }}
        >
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={8}>
            <TextImageInfoComponent
              imageToggle="Right"
              topHeader="WHO ARE WE"
              middleHeader="Closed Loop Process Improvement using AI"
              text="Enkiube Technologies, based in Ontario, Canada, is dedicated to enhancing operational efficiency, reducing costs, and facilitating digital transformation for the industrial sector through innovative artificial intelligence solutions. Our mission is to empower SME and mid-size manufacturing companies by optimizing:"
              list={[
                "Workflows",
                "Documentation management",
                "Compliance processes.",
              ]}
              buttonText="Learn more about our team"
              buttonGradient="linear-gradient(45deg, #9A9CF2 30%, #18A0FB 90%)"
              buttonLink="/company/team"
              imageLink="/mockup2.png"
            />
          </Grid>
          <Grid item xs={0} md={2}></Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={0} sm={0} md={1} lg={1} xl={2}></Grid>
          <Grid item xs={12} sm={12} md={10} lg={10} xl={8}>
            <Grid
              container
              spacing={0}
              sx={{
                position: "relative",
                p: { xs: 0, sm: 0, md: 4 },
                background: "linear-gradient(90deg, #9A9CF2 10%, #18A0FB 90%)",
              }}
            >
              <Grid item xs={0} sm={0} md={0} lg={0} xl={1}></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={5}
                sx={{ p: { xs: 2, sm: 2, md: 2 } }}
              >
                <SingleColTextWithHeaderComponent
                  imageToggle="Right"
                  topHeader="WHY WORK WITH US"
                  middleHeader="First 365 miles, on us!"
                  text="Kick off your digital transformation journey with confidence. Leveraging the CDAP (Canada Digital Adoption Program), we guide you through crafting a bespoke adoption plan tailored to your unique business needs. With Entity360 Suite, your first year is entirely on our dime. 
              We don't just stop at offering our platform for free; we partner with you to fine-tune it, ensuring it seamlessly aligns with your operational goals. "
                  buttonText="Learn more about our solution >"
                  buttonGradient="linear-gradient(45deg, #9A9CF2 30%, #18A0FB 90%)"
                  buttonLink="/solutions/manufacturing"
                  textColor="#FFFFFF"
                  lineHeight="2.0"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                <SingleColTextNoHeaderComponent
                  imageToggle="Right"
                  topHeader="OUR COMMITMENT"
                  middleHeader="Our commitment to your success is through:"
                  list={[
                    {
                      header: "Integrated Solution Architecture",
                      body: "Seamlessly embedding our platform within your existing workflows for immediate impact.",
                    },
                    {
                      header: "Scalable Solutions",
                      body: " Ensuring our technology grows with your business, adaptable to your evolving needs.",
                    },
                    {
                      header: "Uncompromising Security & Compliance",
                      body: " Prioritizing the protection of your data with top-tier security measures, while adhering to industry compliance standards.",
                    },
                  ]}
                  buttonText="Contact Us for More Info >"
                  buttonGradient="linear-gradient(45deg, #9A9CF2 30%, #18A0FB 90%)"
                  buttonLink="/contact-us"
                />
              </Grid>
              <Grid item xs={0} sm={0} md={0} lg={0} xl={2}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={0} sm={0} md={1} lg={1} xl={2}></Grid>
        </Grid>
      </Box>
      <Box>
        <Grid
          container
          spacing={2}
          sx={{
            position: "relative",
            p: { xs: 2, sm: 2, md: 4 },
            mt: { xs: 0, sm: 0, md: 10 },
          }}
        >
          <Grid item xs={0} sm={0} md={2} lg={1} xl={2} />
          <Grid item xs={12} sm={12} md={4} lg={5} xl={4}>
            <SingleColTextWithHeaderComponent
              imageToggle="Right"
              topHeader="HOW WE DO IT"
              middleHeader="Innovative Technology & Services"
              text="Leverage state-of-the-art technology combined with the expertise of our Process Engineers and Machine Learning professionals. We're dedicated to boosting SME efficiency, enhancing asset management, and empowering employees to excel in their roles. "
              buttonText="Checkout our Services >"
              buttonGradient="linear-gradient(45deg, #FFFFFF 30%, #EEEEEE 90%)"
              buttonLink="/solutions/manufacturing"
              textColor="#000000"
              lineHeight="1.5"
              buttonType="round"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={5} xl={4}>
            <Grid
              container
              spacing={2}
              sx={{ position: "relative", py: { xs: 10, sm: 2, md: 0 } }}
            >
              <Grid item xs={12} md={6}>
                <CardComponent
                  imageSrc="/icons/ml.png" // Replace with your image path
                  imageWidthPercentage={50} // This is the width of the image as a percentage of its container
                  header="AI Integration"
                  text="Incorporate advanced AI to streamline processes, improve decision-making, and unlock new insights."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CardComponent
                  imageSrc="/icons/agile.png" // Replace with your image path
                  imageWidthPercentage={50} // This is the width of the image as a percentage of its container
                  header="Custom Solutions"
                  text="Tailored strategies that align with your unique challenge and business goal."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CardComponent
                  imageSrc="/icons/computer.png" // Replace with your image path
                  imageWidthPercentage={50} // This is the width of the image as a percentage of its container
                  header="Choice of Cloud Provider"
                  text="Flexibility to choose the best cloud environment that suits your businesses needs and preference."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CardComponent
                  imageSrc="/icons/encrypted.png" // Replace with your image path
                  imageWidthPercentage={50} // This is the width of the image as a percentage of its container
                  header="Dedicated Data Security"
                  text="Commitment to data integrity with rigorous GDPR compliance."
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0} sm={0} md={2} lg={1} xl={2}></Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          my: { xs: 2, sm: 2, md: 10 },
          p: 0,
          background: "linear-gradient(90deg, #9A9CF2 10%, #18A0FB 90%)",
        }}
      >
        <Grid container spacing={0} sx={{ position: "relative", p: 0 }}>
          <Grid item xs={0} sm={0} md={2} lg={2} xl={2} />
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={5}
            xl={4}
            sx={{ px: { xs: 2, sm: 2, md: 4 }, py: { xs: 2, sm: 2, md: 4 } }}
          >
            <SingleColTextWithHeaderComponent
              imageToggle="Right"
              topHeader="HOW WE DO IT"
              middleHeader="Innovative Technology & Services"
              text="Leverage state-of-the-art technology combined with the expertise of our Process Engineers and Machine Learning professionals. We're dedicated to boosting SME efficiency, enhancing asset management, and empowering employees to excel in their roles. "
              buttonText="Checkout our Services >"
              buttonGradient="linear-gradient(90deg, #9A9CF2 10%, #18A0FB 90%)"
              buttonLink="/solutions/cdap"
              textColor="#FFFFFF"
              lineHeight="2.0"
              buttonType="round"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={6}>
            <img
              src="CDAP.png"
              alt="Description"
              style={{ width: "100%", height: "100%" }}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default HomePage;
