import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import LogoComponent from "../LogoComponent";
import palette from "../../../assets/data/palette";
import pages from "../../../assets/data/pages";

import {
  AppBar,
  Box,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Toolbar,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";

const MobileHeader = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleToggleDropdown = (event, index) => {
    event.stopPropagation(); // Prevent the click event from reaching the Box's onClick
    setOpenDropdowns((prevOpenDropdowns) => ({
      ...prevOpenDropdowns,
      [index]: !prevOpenDropdowns[index],
    }));
  };

  const handleNavigate = (path) => {
    navigate(path);
    setMobileOpen(false); // Close the drawer upon navigation
  };

  const drawer = (
    <Box
      sx={{
        width: { xs: "100%", sm: "100%" }, // 100% width on extra small devices, 40% on others
        color: "white", // Adjust as needed for theme compatibility
        pl: 0,
      }}
      role="presentation"
      onClick={handleDrawerToggle}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        <CloseIcon />
      </Typography>
      <List>
        {pages.map((page, index) => (
          <React.Fragment key={page.parent}>
            <ListItemButton
              onClick={(event) => {
                if (page.children.length > 0) {
                  handleToggleDropdown(event, index); // Call this for pages with children
                } else {
                  handleNavigate(page.address); // Call a different handler for pages without children
                }
              }}
              sx={{
                bgcolor:
                  page.children.length > 0 ? "rgb(0,0,90,0.6)" : "transparent", // Conditional background color
                "&:hover": {
                  bgcolor:
                    page.children.length > 0
                      ? "rgb(0,0,90,0.9)"
                      : "transparent", // Maintain the background color on hover
                },
              }}
            >
              <ListItemText
                primary={page.parent}
                primaryTypographyProps={{ color: "white" }}
              />
              {page.children.length > 0 &&
                (openDropdowns[index] ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
            <Collapse in={openDropdowns[index]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {page.children.map((child) => (
                  <ListItemButton
                    key={child.name}
                    sx={{
                      pl: 2,
                    }}
                    onClick={() => handleNavigate(child.address)}
                  >
                    <ListItemText
                      primary={child.name}
                      primaryTypographyProps={{ color: "white" }}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position="static" style={{ background: palette["C7"] }}>
      <Toolbar sx={{ px: 5 }}>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", md: "none" },
            justifyContent: "space-between",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
            sx={{
              "& .MuiDrawer-paper": {
                width: { xs: "60%", sm: "40%" },
                bgcolor: "rgba(0, 0, 0, 0.7)", // Dark background with some opacity for the drawer
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <LogoComponent xs="flex" md="none" />
      </Toolbar>
    </AppBar>
  );
};

export default MobileHeader;
