import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Card,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";

import { submitCDAPForm } from "../../slices/submitFormsSlice";

const ContactUs = () => {
  const dispatch = useDispatch();
  const [captcha, setCaptcha] = useState("");
  const [form, setForm] = useState({
    inquiryOption: "CDAP Consulting",
    message: "Not Applicable",
  });
  const [formMessage, setFormMessage] = useState("");

  const captchaRef = useRef(null);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const validateGeneral = (form) => {
    if (!form["name"] || !form["company"]) {
      return false;
    }

    return true;
  };

  const onFormChangeHandler = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onFormSubmitHandler = (e) => {
    e.preventDefault();
    if (validateEmail(form["email"])) {
      if (validateGeneral(form)) {
        // dispatch(submitEvaluate(form));
        dispatch(submitCDAPForm(form));
        setFormMessage("Email sent succesfully");
      } else {
        setFormMessage("Please fill out all required fields");
      }
    } else {
      setFormMessage("Invalid Email Provided");
    }
  };

  return (
    <Card sx={{ maxWidth: 600, mx: "auto", mt: 4 }}>
      <Box sx={{ p: 3 }}>
        <Typography variant="h5" component="h1" textAlign="center" gutterBottom>
          Contact us to Evaluate
        </Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={onFormSubmitHandler}
        >
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel htmlFor="name">Your Name</InputLabel>
            <Input
              id="name"
              name="name"
              onChange={onFormChangeHandler}
              required
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel htmlFor="company">Company Name</InputLabel>
            <Input
              id="company"
              name="company"
              onChange={onFormChangeHandler}
              required
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input
              id="email"
              name="email"
              type="email"
              onChange={onFormChangeHandler}
              required
            />
            <FormHelperText>Email we can reach you at</FormHelperText>
          </FormControl>
          <Box mb={2}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              onChange={(response) => setCaptcha(response)}
              ref={captchaRef}
            />
          </Box>
          {formMessage && (
            <Typography color="error" textAlign="center">
              {formMessage}
            </Typography>
          )}
          <Button
            type="submit"
            disabled={!captcha}
            fullWidth
            variant="contained"
            sx={{ py: "10px", mt: 2 }}
          >
            Boost your Business!
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default ContactUs;
