import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppRoute from "./routes/AppRoute";
import { HomePage } from "./modules/home/pages";
import AppLayout from "./shared/ui/AppLayout";
import './App.css'
import CompanyPage from "./modules/company/pages/company";
import WhoWeArePage from "./modules/company/pages/whoWeAre";
import NewsPage from "./modules/company/pages/news";
import SingleNewsPage from "./modules/company/pages/singleNews";
import PlatformOverviewPage from "./modules/platform/pages/overviewPage";
import FeaturePage from "./modules/platform/pages/FeaturePage";
import QaCopilotPage from "./modules/solution/pages/qaCopilotPage";
import ComplianceAuditPage from "./modules/solution/pages/auditPage";
import CDAPPage from "./modules/solution/pages/cdap";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import FAQ from "./modules/resource_center/pages/questionPage";
import PrivacyPolicyPage from "./modules/resource_center/pages/privacyPolicyPage";
import TermsConditionsPage from "./modules/resource_center/pages/termsConditionsPage";
import ContactUsPage from "./modules/contact_us/pages/contactUsPage";

const theme = createTheme({
  // Customize your theme here
  breakpoints: {
    // If you need custom breakpoints
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  // other theme customizations
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
    <Router>
      <Routes>
          <Route element={<AppLayout />}>
            <Route path="/" element={<AppRoute element={<HomePage />} />} />
            <Route path="/company" element={<AppRoute element={<CompanyPage />} />} />
            <Route path="/company/team" element={<AppRoute element={<WhoWeArePage />} />} />
            <Route path="/company/news" element={<AppRoute element={<NewsPage />} />} />
            <Route path="/company/news/:slug" element={<AppRoute element={<SingleNewsPage />} />} />
            <Route path="/platform" element={<AppRoute element={<PlatformOverviewPage />} />} />
            <Route path="/platform/features" element={<AppRoute element={<FeaturePage />} />} />
            <Route path="/solutions/manufacturing" element={<AppRoute element={<QaCopilotPage />} />} />
            <Route path="/solutions/auditors" element={<AppRoute element={<ComplianceAuditPage />} />} />
            <Route path="/solutions/cdap" element={<AppRoute element={<CDAPPage />} />} />
            <Route path="/resources/faq" element={<AppRoute element={<FAQ />} />} />
            <Route path="/resources/privacy" element={<AppRoute element={<PrivacyPolicyPage />} />} />
            <Route path="/resources/terms" element={<AppRoute element={<TermsConditionsPage />} />} />
            <Route path="/contact-us" element={<AppRoute element={<ContactUsPage />} />} />
          </Route>

      </Routes>
    </Router>
    </ThemeProvider>
  );
};

export default App;
