import React from "react";
import { Box } from "@mui/material";
import LogoIcon from "./SvgComponent";

const LogoComponent = (props) => {
  return (
    <Box position="relative" sx={{ display: { xs: props.xs, md: props.md } }}>
      <LogoIcon width="100px" height="70px" fill="transparent" />
      <span className="comfortaa" style={{ paddingTop: 20 }}>
        <span style={{ color: "#18A0FB" }}>Enkiube</span>
        <br />
        Technologies
      </span>
    </Box>
  );
};
export default LogoComponent;
