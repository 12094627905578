import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Button,
} from "@mui/material";

const ValuePropComponent = ({
  topHeader,
  header,
  buttonColorGradient,
  cardsData,
  hasButton = true,
  buttonText,
  cardShadow = "none",
  cardHeight = "140",
}) => {
  return (
    <Box sx={{ flexGrow: 1, padding: 4 }}>
      <Typography
        variant="overline"
        display="block"
        gutterBottom
        color="#18A0FB"
      >
        {topHeader}
      </Typography>
      <Typography variant="h5" gutterBottom>
        {header}
      </Typography>
      <Grid container spacing={4} sx={{ mt: 3 }}>
        {cardsData.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{
                height: "100%",
                border: 0,
                boxShadow: cardShadow,
                background: "transparent",
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  width: "60%", // Control the width of the image
                  margin: "auto", // Center the image
                  display: "block", // Allows margin: auto to work
                }}
                image={card.image}
                alt="Partner image"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  textAlign="center"
                  sx={{
                    position: "relative",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: "50%",
                      transform: "translateX(-50%)",
                      width: "80%",
                      height: "1px",
                      background: "#BBBBBB",
                    },
                    mt: 1,
                    pt: 2,
                  }}
                >
                  {card.header}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  textAlign="center"
                >
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        {hasButton && (
          <Button variant="contained" sx={{ background: buttonColorGradient }}>
            {buttonText}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ValuePropComponent;
