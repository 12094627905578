import { configureStore } from "@reduxjs/toolkit";
import submitFormReducer from "../shared/slices/submitFormsSlice"
import articlesReducer from  "../shared/slices/articlesSlice"

export const store = configureStore({
  reducer: {
    submitForm: submitFormReducer,
    articles: articlesReducer
  },
});
