import React from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  Avatar,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
const NewsCardV1Component = ({
  headerImageUrl,
  avatarImageUrl,
  titleText,
  subtitle,
  readMoreUrl,
}) => {
  const navigate = useNavigate();
  const onClickHandler = (path) => {
    navigate(path);
  };
  return (
    <Card
      sx={{ maxWidth: 300, height: 350, position: "relative", mb: 5, mt: 2 }}
    >
      <CardMedia
        component="img"
        height="140"
        image={headerImageUrl}
        alt="Header Image"
      />
      <Box sx={{ position: "absolute", top: "100px", left: "10%" }}>
        <Avatar
          alt="Avatar Image"
          src={avatarImageUrl}
          sx={{ width: 56, height: 56 }}
        />
      </Box>
      <CardActionArea target="_blank" sx={{ pt: 7, px: 3 }}>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{ textAlign: "left", fontSize: "1.0rem" }}
        >
          {titleText}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ textAlign: "left" }}
        >
          {subtitle.length > 100
            ? `${subtitle.substring(0, 100)}...`
            : subtitle}
        </Typography>
        <Typography sx={{ color: "blue", textAlign: "left", my: 2 }}>
          <Button
            onClick={() => onClickHandler(readMoreUrl)}
            color="inherit"
            underline="hover"
            sx={{ color: "blue", textAlign: "left", my: 2 }}
          >
            Read more
          </Button>
        </Typography>
      </CardActionArea>
    </Card>
  );
};

export default NewsCardV1Component;
