const cdap = {
    "intro":{
        "topHeader":'WHAT IS CDAP?',
        "header":"Canada Digital Adoption Program",
        "text":"If you own a small to mid-size business we can provide expert advice to implement solutions to increase online sales, reduce costs, better manage inventory and more. As CDAP Program Digital Advisors, we help evaluating your business for Grants and Financing opportunities available to improve your operation:",
        "cards":[
            {
                "imageUrl":"/CDAP1.jpeg",
                "title": "Boost your Business Grant",
                "text": "Get up to $15,000 grant to reduce overhead costs, speed up transactions, respond to clients more quickly, manage inventory more efficiently, improve supply chain logistics",
                "buttonText": "Learn more about this program",
                "buttonLink": "https://ised-isde.canada.ca/site/canada-digital-adoption-program/en/grow-your-business-online-get-started"
            },
            {
                "imageUrl":"/CDAP2.jpeg",
                "title": "Boost your Business Grant",
                "text": "Get up to $2,400 to get online, increase sales, adopt security software, connect with customers, improve social media marketing, improve user experience for online customers.",
                "buttonText": "Learn more about this:",
                "buttonLink": "https://ised-isde.canada.ca/site/canada-digital-adoption-program/en/about-canada-digital-adoption-program#details-panel-09"
            }            
        ]

    },

    "contact us": {
        "callToAction":"Is Boost Your Business Technology Grant Right For You?",
        "text":"The Boost Your Business Technology grant gives you up to $15,000 to get advice and up to $100,000 in interest free loans from the Business Development Bank of Canada (BDC) to implement the technologies that can propel your business forward. You’ll have access to an expert digital advisor who will help you develop a plan tailored to your business needs.",
        "buttonColor": "#9A9CF2",
        "buttonText": "Contact our sales team...",
        "buttonUrl": "/"
    }

}

export default cdap