import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

const renderText = (text) => {
  // Replace <b> tags with <strong>
  const boldText = text.replace(/<b>(.*?)<\/b>/g, (_, p1) => (
    <strong>{p1}</strong>
  ));
  // Replace <div> tags with paragraph breaks
  const paragraphs = boldText.split(/<\/div><div>/g).map((paragraph, index) => (
    <Typography key={index} variant="body1" gutterBottom>
      {paragraph.replace(/<\/?div>/g, "")}
    </Typography>
  ));
  return paragraphs;
};

const InfoOnlyTextWithIconComponent = ({
  topHeader,
  middleHeader,
  text,
  IconComponent, // Expecting an MUI Icon component
  color = "primary",
}) => {
  const theme = useTheme();
  return (
    <div>
      <Box sx={{ position: "relative", p: 0, display: "flex" }}>
        <Typography
          variant="h5"
          component="span"
          sx={{
            fontWeight: "bold",
            lineHeight: "1",
            marginRight: theme.spacing(2),
            color: "#333", // Adjust the color if needed
          }}
        >
          {IconComponent && (
            <IconComponent
              color="primary"
              sx={{ fontSize: 60, color: color, mb: 2 }}
            />
          )}
        </Typography>
        <Box>
          <Typography
            variant="overline"
            component="span"
            color="primary"
            gutterBottom
          >
            {topHeader}
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom color="black">
            {middleHeader}
          </Typography>
        </Box>
      </Box>
      {renderText(text)}
    </div>
  );
};

export default InfoOnlyTextWithIconComponent;
