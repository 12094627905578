const audit = {
    "feature":{
        "header":'Improve Audit Quality with Generative AI',
        "subHeader":"Enhancing Efficiency & Accuracy for Auditors and Compliance Professionals.",
        "text":"",
        "imageUrl":"/snapshot10.jpg",
        "buttomText": "By harnessing the power of AI, we ensure every step of your manufacturing line is optimized for accuracy, compliance, and speed, transforming challenges into opportunities for growth and innovation.",
        "buttons":[
            {
                "buttonText":"Learn More...",
                "buttonLink":"/platform",
                "buttonGradient":'rgba(0,0,0,1)'
            },
            {
                "buttonText":"Request for Demo...",
                "buttonLink":"/contact-us",
                "buttonGradient":'rgba(0,0,0,1)'
            }
        ]
    },
    "problems":[
        {
            "image":"/icons/compliant (1).png",
            "header":"Evolving Compliance Landscape",
            "description":"Auditors navigate the complex challenge of ensuring adherence to constantly changing regulations and standards, which demands continuous vigilance and adaptation."   
        },
        {
            "image":"/icons/auditor.png",
            "header":"Traditional Audit Process Challenges",
            "description":"Traditional methods are marred by time-consuming tasks and human errors, struggling to stay current with compliance requirements and leading to inefficiencies."   
        },
        {
            "image":"/icons/non-conforming.png",
            "header":"Risk of Non-Compliance",
            "description":"The lag in updating audit processes to reflect the latest standards increases the risk of non-compliance, posing significant legal and financial implications for companies."   
        }                
    ],
    "solution":{
        "text":[
            "Our Quality Assurance Co-pilot, powered by GenAI, revolutionizes the audit process by integrating dynamic, real-time documentation access with automated compliance checks, thereby streamlining audits and enhancing efficiency. Leveraging advanced multi-modal document understanding, the Co-pilot acts as an intelligent assistant, dynamically assessing compliance and identifying potential deviations during audits. This allows auditors to concentrate on the audit's intricacies rather than on note-taking. The Co-pilot facilitates the integration of audit sessions into our platform.",
            "By referencing standard documents under review, it evaluates each session against predefined criteria, offers rankings, and generates comprehensive reports. This innovative approach ensures auditors can devote their full attention to the audit process itself, bolstered by the Co-pilot's analytical support.",
            "Furthermore, our platform enriches your audit strategy with insights into the historical performance of standard documents across past audits, internal and external alike. This feature directs your focus toward areas of recurrent challenges, enabling targeted improvements and strategic oversight."
        ]
    },
    "value proposition":[
        {
            "number":1,
            "gradient":"linear-gradient(0deg, #9A9CF2 30%, #18A0FB 90%)",
            "title":"Increased Efficiency and Time Savings",
            "text":"Automate routine compliance checks and documentation reviews, significantly reducing the time required for audits. Auditors can complete their work quicker with less resources."
        },
        {
            "number":2,
            "gradient":"linear-gradient(0deg, #9A9CF2 30%, #18A0FB 90%)",
            "title":"Enhanced Accuracy and Reduced Error Rates",
            "text":"Quality Assurance Co-pilot minimizes human errors. Its ability to consistently apply the latest standards ensures that nothing is overlooked, enhancing the overall accuracy of audits."
        },
        {
            "number":3,
            "gradient":"linear-gradient(0deg, #9A9CF2 30%, #18A0FB 90%)",
            "title":"Real-Time Compliance Monitoring",
            "text":"real-time access to documentation and compliance statuses, enabling auditors to identify and address potential issues as they occur proactively."
        },
        {
            "number":4,
            "gradient":"linear-gradient(0deg, #9A9CF2 30%, #18A0FB 90%)",
            "title":"Data-Driven Insights for Continuous Improvement",
            "text":"By providing actionable insights into recurring compliance challenges & enabling organizations to target specific areas for improvement, fostering a culture of  strategic compliance management."
        },
        {
            "number":5,
            "gradient":"linear-gradient(0deg, #9A9CF2 30%, #18A0FB 90%)",
            "title":"Risk Mitigation",
            "text":"Co-pilot helps mitigate the risks associated with non-compliance, such as legal penalties, financial losses, and reputational damage. Organizations can ensure they remain on the right side of regulations and standards."
        },
        {
            "number":6,
            "gradient":"linear-gradient(0deg, #9A9CF2 30%, #18A0FB 90%)",
            "title":"Strategic Audit Planning",
            "text":"By offering a comprehensive overview of compliance across different departments or locations, enabling auditors to strategically plan their audits based on risk assessments & historical performance."
        },
        {
            "number":7,
            "gradient":"linear-gradient(0deg, #9A9CF2 30%, #18A0FB 90%)",
            "title":"Empowered Auditors",
            "text":"Freeing auditors from the tedium of manual checks and note-taking allows them to focus on the nuanced aspects of their work and leveraging their expertise where it matters most."
        }                                                
    ],
    "contact us": {
        "text":"Transform your auditing and compliance processes with the power of GenAI. Contact us to learn more and see our solutions in action",
        "buttonColor": "#9A9CF2",
        "buttonText": "Contact our sales team...",
        "buttonUrl": "/contact-us"
    }

}

export default audit