import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Chip,
  Button,
  Stack,
  CardActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const NewsCardComponent = ({ newsItem }) => {
  const navigate = useNavigate();

  const handleReadMoreClick = () => {
    navigate(`/company/news/${newsItem.slug.current}`);
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="140"
        image={newsItem.headerImageUrl.asset.url}
        alt="News image"
      />
      <CardContent>
        <Stack direction="row" spacing={1} mb={2}>
          {newsItem.tags.map((tag) => (
            <Chip
              key={tag.tagName}
              label={tag.tagName}
              size="small"
              sx={{ background: tag.tagColor.hex, color: "white" }}
            />
          ))}
        </Stack>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          {new Date(newsItem.date).toISOString().split("T")[0]}
        </Typography>
        <Typography gutterBottom variant="h6" component="div">
          {newsItem.titleText}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {newsItem.subTitle}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleReadMoreClick}>
          Read more
        </Button>
      </CardActions>
    </Card>
  );
};

export default NewsCardComponent;
