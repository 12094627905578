import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  CardMedia,
  useTheme,
} from "@mui/material";
import CirclesComponent from "../../../shared/component/backgrounds/CirclesComponent";

const FeatureComponent = ({
  header,
  subHeader,
  text,
  button1Gradient,
  button1Text,
  button1Link,
  button2Gradient,
  button2Text,
  button2Link,
  imageURL,
}) => {
  const theme = useTheme();

  const gradientButtonStyle = (gradient) => ({
    backgroundImage: gradient,
    color: theme.palette.getContrastText(gradient),
  });

  return (
    <Box sx={{ flexGrow: 1, overflow: "hidden", px: 3 }}>
      <CirclesComponent bgcolor="grey.200" />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h4" component="h2" gutterBottom>
            <b>{header}</b>
          </Typography>
          <Typography variant="h4" color="textSecondary" gutterBottom>
            {subHeader}
          </Typography>
          <Typography variant="h6" paragraph>
            {text}
          </Typography>
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mb: 2 }}>
            <Button
              variant="contained"
              sx={gradientButtonStyle(button1Gradient)}
              href={button1Link}
            >
              {button1Text}
            </Button>
            <Button
              variant="contained"
              sx={gradientButtonStyle(button2Gradient)}
              href={button2Link}
            >
              {button2Text}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <CardMedia
            component="img"
            image={imageURL}
            alt={header}
            sx={{ width: 325, height: 325, borderRadius: "600px" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeatureComponent;
