import React from "react";
import { Grid, Box } from "@mui/material";
import Typography from "@mui/material/Typography";

import BreadCrumbsComponent from "../../../shared/component/breadcrumbs/BreadCrumbComponent";
import CirclesComponent from "../../../shared/component/backgrounds/CirclesComponent";

const breadcrumbItems = [
  { redirectUrl: "/", text: "Home" },
  { redirectUrl: "/resources/privacy", text: "Privacy Policy" },
];

const PrivacyPolicyPage = () => {
  return (
    <Box>
      <BreadCrumbsComponent items={breadcrumbItems} />
      <CirclesComponent />
      <Grid container sx={{ position: "relative", mb: 20 }}>
        <Grid item xs={0} sm={1} md={2}></Grid>
        <Grid item xs={0} sm={10} md={8}>
          <Typography variant="h4" component="p" gutterBottom>
            Privacy Policy
          </Typography>
        </Grid>
        <Grid item xs={0} sm={1} md={2}></Grid>
        <Grid item xs={0} sm={1} md={2}></Grid>
        <Grid item xs={0} sm={1} md={8}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Effective Date: Jan 1, 2024
              </Typography>
              <Typography paragraph>
                Welcome to Enkiube Technologies. Your privacy and the security
                of your personal information are of paramount importance to us.
                This Privacy Policy outlines the types of information we collect
                from our users, how we use it, and the measures we take to
                protect it.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <b>Information Collection</b>
              </Typography>
              <Typography paragraph>
                We collect information to provide better services to all our
                users. The information Enkiube Technologies collects, and how
                that information is used, depends on how you use our services
                and how you manage your privacy controls.
              </Typography>
              <Typography paragraph>
                Personal Information: This includes information you provide to
                us directly, such as your name, email address, company
                affiliation, and phone number when you register for our
                services, subscribe to our newsletter, or interact with our
                customer service.
              </Typography>
              <Typography paragraph>
                Usage Information: We collect information about how you use our
                services, including search queries, page interaction
                information, and device information.
              </Typography>
              <Typography paragraph>
                Location Information: With your permission, we may collect
                information about your actual location to provide location-based
                services.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <b>Use of Information</b>
              </Typography>
              <Typography paragraph>
                We use the information we collect to provide, maintain, and
                improve our services, develop new ones, and protect Enkiube
                Technologies and our users. We also use this information to
                offer you tailored content – like giving you more relevant
                search results and ads.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <b>Information Sharing</b>
              </Typography>
              <Typography paragraph>
                Enkiube Technologies does not share your personal information
                with companies, organizations, or individuals outside of Enkiube
                Technologies except in the following cases:
              </Typography>
              <Typography paragraph>With your consent.</Typography>
              <Typography paragraph>
                For external processing (we provide personal information to our
                affiliates or other trusted businesses or persons to process it
                for us, based on our instructions and in compliance with our
                Privacy Policy and any other appropriate confidentiality and
                security measures).
              </Typography>
              <Typography paragraph>
                For legal reasons (to meet any applicable law, regulation, legal
                process or enforceable governmental request).
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <b>Data Protection</b>
              </Typography>
              <Typography paragraph>
                We work hard to protect Enkiube Technologies and our users from
                unauthorized access to or unauthorized alteration, disclosure,
                or destruction of information we hold. In particular:
              </Typography>
              <Typography paragraph>
                We encrypt many of our services using SSL.
              </Typography>
              <Typography paragraph>
                We offer you verification when you access your Enkiube
                Technologies account, and we review our information collection,
                storage, and processing practices to guard against unauthorized
                access to systems.
              </Typography>
              <Typography paragraph>
                We restrict access to personal information to Enkiube
                Technologies employees, contractors, and agents who need to know
                that information to process it for us, and who are subject to
                strict contractual confidentiality obligations.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <b>Your Privacy Controls</b>
              </Typography>
              <Typography paragraph>
                You have choices regarding the information we collect and how
                it's used. This includes:
              </Typography>
              <Typography paragraph>
                Adjust your browser settings to block all cookies, including
                cookies associated with our services.
              </Typography>
              <Typography paragraph>
                Accessing or rectifying the personal data we hold about you.
              </Typography>
              <Typography paragraph>Deleting your account with us.</Typography>
              <Typography variant="subtitle1" gutterBottom>
                Changes to the Privacy Policy
              </Typography>
              <Typography paragraph>
                We may change this Privacy Policy from time to time. We will
                post any privacy policy changes on this page and, if the changes
                are significant, we will provide a more prominent notice.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <b>Contact Us</b>
              </Typography>
              <Typography paragraph>
                If you have any questions about this Privacy Policy, please
                contact us at info@enkiube.com
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0} sm={1} md={2}></Grid>
      </Grid>
    </Box>
  );
};

export default PrivacyPolicyPage;
