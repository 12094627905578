import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import LogoComponent from "../LogoComponent";
import palette from "../../../assets/data/palette";
import pages from "../../../assets/data/pages"; // Assuming pages is moved to a separate file
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Menu,
  Button,
  MenuItem,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const DesktopHeader = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [dropdowns, setDropdowns] = useState({});
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleToggleDropdown = (event, index) => {
    event.stopPropagation(); // Prevent the click event from reaching the Box's onClick
    setOpenDropdowns((prevOpenDropdowns) => ({
      ...prevOpenDropdowns,
      [index]: !prevOpenDropdowns[index],
    }));
  };

  const handleNavigate = (path) => {
    navigate(path);
    setMobileOpen(false); // Close the drawer upon navigation
  };

  const handleCloseDropdown = (index, childPath = null) => {
    setDropdowns({
      ...dropdowns,
      [index]: null, // Clear the anchor element for the dropdown
    });
    if (childPath) {
      navigate(childPath); // Navigate to the path
    }
  };

  const handleDirectLink = (event, page) => {
    navigate(page["address"]);
  };

  const handleOpenDropdown = (event, index) => {
    setDropdowns({
      ...dropdowns,
      [index]: event.currentTarget, // Set the anchor element for the dropdown
    });
  };
  return (
    <AppBar position="static" style={{ background: palette["C7"] }}>
      <Toolbar sx={{ px: 5 }}>
        <LogoComponent xs="none" md="flex" />
        <Box
          sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
          justifyContent="flex-end"
        >
          {pages.map((page, index) => (
            <div key={page.parent}>
              <Button
                aria-controls={dropdowns[index] ? `menu-${index}` : undefined}
                aria-haspopup="true"
                aria-expanded={dropdowns[index] ? "true" : undefined}
                onClick={(event) =>
                  page["children"].length > 0
                    ? handleOpenDropdown(event, index)
                    : handleDirectLink(event, page)
                }
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page.parent}
                {page.children.length > 0 && (
                  <span>
                    <ArrowDropDownIcon
                      style={{
                        fontSize: "medium",
                        display: "inline-flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    />
                  </span>
                )}
              </Button>
              {page.children.length > 0 && (
                <Menu
                  id={`menu-${index}`}
                  anchorEl={dropdowns[index]}
                  open={Boolean(dropdowns[index])}
                  onClose={() => handleCloseDropdown(index)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  sx={{ mt: 2 }}
                  PaperProps={{
                    sx: { borderRadius: 0, background: "#213765" },
                  }}
                >
                  {page.children.map((child) => (
                    <MenuItem
                      sx={{ background: "#213765", color: "#ffffff" }}
                      key={child["name"]}
                      onClick={() =>
                        handleCloseDropdown(index, child["address"])
                      }
                    >
                      <Typography textAlign="center">
                        {child["name"]}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </div>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default DesktopHeader;
