import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import LogoIcon from "./SvgComponent";

const LogoVerticalComponent = (props) => {
  return (
    <Box sx={{ display: { xs: props.xs, md: props.md }, alignItems: 'center' }}>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          {/* LogoIcon should take the full width of its container and be centered */}
          <LogoIcon fill="transparent" sx={{ display: 'block' }} />
        </Grid>
        <Grid item xs={12}>
          {/* Typography for the text, centered as well */}
          <Typography  variant="h6" gutterBottom component="div" sx={{textAlign: { xs: "center", sm: "center" }}}>
            <span className="comfortaa" style={{color: "#9A9CF2"}}>Enkiube</span>
          </Typography>
          <Typography  gutterBottom component="div" sx={{textAlign: { xs: "center", sm: "center" }}}>
          <span className="comfortaa">Technologies</span>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LogoVerticalComponent;
