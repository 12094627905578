import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";

const BreadCrumbsComponent = ({ items }) => {
  return (
    <Paper
      elevation={0}
      style={{
        padding: "8px",
        backgroundColor: "#18A0FB",
        borderRadius: "0px",
      }}
      sx={{ mb: { xs: 5, md: 10 } }}
    >
      <Grid container>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ color: "White" }}>
            {items.map((item, index) => {
              const isLastItem = index === items.length - 1;
              return isLastItem ? (
                <Typography color="white" key={index}>
                  {item.text}
                </Typography>
              ) : (
                <Link
                  underline="hover"
                  color="inherit"
                  href={item.redirectUrl}
                  key={index}
                >
                  {item.text}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Grid>
        <Grid item xs={0} md={2}></Grid>
      </Grid>
    </Paper>
  );
};

export default BreadCrumbsComponent;
