import React from "react";
import { Box } from "@mui/material";

const FullPageVideoComponent = () => {
  return (
    <Box
      sx={{
        position: "absolute", // Use 'absolute' or 'fixed' depending on your needs
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -1, // Ensure the video is behind other content
        "&::after": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Black tint overlay
        },
      }}
    >
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          objectFit: "cover", // Ensures the video covers the whole screen without distortion
        }}
      >
        J:\2024 Enkiube Github\enkiube
        website\enkiube_website\public\manufacturing_1080.mp4
        <source src="/manufacturing_1080.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
};

export default FullPageVideoComponent;
