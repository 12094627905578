import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

const NumberedBlockComponent = ({ number, title, text, gradient }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        height: { xs: "18vh", sm: "12vh", md: "10vh", lg: "10vh" },
        alignItems: "center",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(2),
        backgroundColor: "#fff",
        borderLeft: `8px solid`,
        borderRight: `3px solid`,
        borderBottom: `1px solid`,
        borderTop: `1px solid`,
        borderImageSlice: 1,
        borderImageSource: gradient,
        background: "rgba(255,255,255,0.6)",
      }}
    >
      <Typography
        variant="h5"
        component="span"
        sx={{
          fontWeight: "bold",
          fontSize: { xs: "3.5rem", sm: "3.5rem", md: "3.5rem", lg: "4.5rem" },
          lineHeight: "1",
          marginRight: theme.spacing(2),
          color: "#333", // Adjust the color if needed
        }}
      >
        {number}
      </Typography>
      <Box>
        <Typography
          variant="subtitle1"
          component="h4"
          sx={{
            fontSize: {
              xs: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
            },
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: {
              xs: "0.7rem",
              sm: "0.7rem",
              md: "0.8rem",
              lg: "0.8rem",
            },
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default NumberedBlockComponent;
