import React from "react";

import { Grid, Box, Typography, Button } from "@mui/material";

import BreadCrumbsComponent from "../../../shared/component/breadcrumbs/BreadCrumbComponent";

import FeatureComponent from "../components/featureComponent";
import manufacturing from "../../../assets/data/solutions";
import ProblemComponent from "../components/ProblemComponent";
import MultiTextImageInfoComponent from "../../../shared/component/infoBlocks/multiTextImage";
import ValuePropComponent from "../components/ValuePropComponent";

const QaCopilotPage = () => {
  const breadcrumbItems = [
    { redirectUrl: "/", text: "Home" },
    { redirectUrl: "/solutions/manufacturing", text: "Solutions" },
    { redirectUrl: "/solutions/manufacturing", text: "Manufacturing" },
  ];
  return (
    <div>
      <BreadCrumbsComponent items={breadcrumbItems} />
      <Box sx={{}}>
        <Grid
          container
          spacing={5}
          sx={{
            position: "relative",
            py: 0,
            mb: { sx: 0, sm: 0, md: 10, lg: 10 },
          }}
        >
          <Grid item xs={0} md={3} />
          <Grid item xs={12} md={8}>
            <FeatureComponent
              header={manufacturing["feature"]["header"]}
              subHeader={manufacturing["feature"]["subHeader"]}
              text={manufacturing["feature"]["text"]}
              button1Gradient={
                manufacturing["feature"]["buttons"][0]["buttonGradient"]
              }
              button1Text={manufacturing["feature"]["buttons"][0]["buttonText"]}
              button1Link={manufacturing["feature"]["buttons"][0]["buttonLink"]}
              button2Gradient={
                manufacturing["feature"]["buttons"][1]["buttonGradient"]
              }
              button2Text={manufacturing["feature"]["buttons"][1]["buttonText"]}
              button2Link={manufacturing["feature"]["buttons"][1]["buttonLink"]}
              imageURL={manufacturing["feature"]["imageUrl"]}
            />
          </Grid>
          <Grid item xs={0} md={1} />
          <Grid item xs={0} md={3} />
          <Grid item xs={12} md={6}>
            <Typography variant="h6" color="textSecondary" textAlign="center">
              {manufacturing["feature"]["bottomText"]}
            </Typography>
          </Grid>
          <Grid item xs={0} md={3} />
        </Grid>
        <Grid
          container
          spacing={0}
          sx={{
            position: "relative",
            py: 0,
            my: 0,
            background: "#eeeeee",
          }}
        >
          {/* Partnerships */}
          <Grid item xs={0} md={3} />
          <Grid item xs={12} md={6} sx={{ my: { xs: 2, sm: 2, md: 2 } }}>
            <ProblemComponent
              topHeader="WHAT ARE WE TRYING TO SOLVE?"
              header="Problem Statement"
              buttonColorGradient="linear-gradient(45deg, #9A9CF2 30%, #18A0FB 90%)"
              hasButton={false}
              buttonText="Contact us for partnerhsrip"
              cardsData={manufacturing["problems"]}
              cardShadow="none"
              cardHeight="140"
            />
          </Grid>
          <Grid item xs={0} md={3} />
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={0} md={3} sx={{ mt: { xs: 2, sm: 2, md: 2 } }} />
          <Grid item xs={12} md={6} sx={{ mt: { xs: 2, sm: 2, md: 2 } }}>
            <MultiTextImageInfoComponent
              imageToggle="Left"
              topHeader="HOW ARE WE TRYING TO SOLVE IT?"
              middleHeader="Solution Overview"
              text={[
                {
                  header: "",
                  paragraph: manufacturing["solution"]["text"],
                },
              ]}
              haveButton={false}
              imageLink="/snapshot9.png"
              topMargin={5}
              moreText={true}
            />
          </Grid>
          <Grid item xs={0} md={3} sx={{ mt: { xs: 2, sm: 2, md: 2 } }} />
        </Grid>
        <Box sx={{ zIndex: -1, height: 0, mt: -20 }}>
          <Typography
            textAlign="center"
            sx={{ fontSize: { xs: 200, md: 620 }, color: "#eeeeee" }}
          >
            360
          </Typography>
        </Box>
        <Grid
          container
          spacing={0}
          sx={{ mt: 20, mb: { xs: 2, sm: 2, md: 15 } }}
        >
          <Grid item xs={0} md={2} sx={{ mt: { xs: 2, sm: 2, md: 2 } }} />
          <Grid item xs={12} md={8} sx={{ my: { xs: 2, sm: 2, md: 2 } }}>
            <ValuePropComponent
              topHeader="WHAT ARE WE TRYING TO SOLVE?"
              header="Problem Statement"
              buttonColorGradient="linear-gradient(45deg, #9A9CF2 30%, #18A0FB 90%)"
              hasButton={false}
              buttonText="Contact us for partnerhsrip"
              cardsData={manufacturing["value proposition"]}
              cardShadow="none"
              cardHeight="140"
            />
          </Grid>
          <Grid item xs={0} md={2} />
          <Grid item xs={0} md={4} />
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography textAlign="center" variant="h6" sx={{ mb: 5, px: 5 }}>
              {manufacturing["contact us"]["text"]}
            </Typography>
            <Button
              href={manufacturing["contact us"]["buttonUrl"]}
              variant="contained"
              sx={{
                mb: 5,
                background: manufacturing["contact us"]["buttonColor"],
              }}
            >
              {manufacturing["contact us"]["buttonText"]}
            </Button>
          </Grid>
          <Grid item xs={0} md={4} />
        </Grid>
      </Box>
    </div>
  );
};

export default QaCopilotPage;
