import React, { useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import teamMembers from "../../../assets/data/team";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function TeamComponent() {
  const [selectedMember, setSelectedMember] = useState(teamMembers[0]);

  const renderText = (html) => {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

  const handleMemberClick = (member) => {
    setSelectedMember(member);
  };

  const openLinkedIn = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <Grid container spacing={5} sx={{ p: { xs: 2, sm: 0, md: 0, lg: 0 } }}>
        {teamMembers.map((member, index) => (
          <Grid item xs={12} sm={6} md={6} key={index}>
            <Card
              sx={{
                maxWidth: 345,
                margin: "auto",
                height: "200px",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <CardActionArea onClick={() => handleMemberClick(member)}>
                <CardMedia
                  component="img"
                  image={member.imageLink}
                  alt={`${member.Name} ${member.FamilyName}`}
                  sx={{
                    height: "200px",
                    filter: "grayscale(100%)",
                    transition: "filter 1s, transform 1s",
                    "&:hover": {
                      objectFit: "fit",
                      filter: "grayscale(0%) blur(2px)",
                      transform: "scale(1.05)",
                    },
                    ".MuiCardActionArea-root:hover &": {
                      objectFit: "fit",
                      filter: "grayscale(0%) blur(2px)",
                      transform: "scale(1.05)",
                    },
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 20,
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    opacity: 0,
                    transition: "opacity 1s",
                    ".MuiCardActionArea-root:hover &": {
                      opacity: 1,
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "common.white",
                      textAlign: "center",
                      backgroundColor: "transparent",
                    }}
                  >
                    {member.Name} {member.FamilyName}
                  </Typography>
                </Box>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      {selectedMember && (
        <Box mt={5}>
          <Typography variant="h4" component="div">
            {selectedMember.Name} {selectedMember.FamilyName}
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h6" component="span">
              <b>{selectedMember.Title}</b>
            </Typography>
            <IconButton
              onClick={() => openLinkedIn(selectedMember.SocialMedia)}
            >
              <LinkedInIcon sx={{ color: "#0e76a8" }} />
            </IconButton>
          </Box>
          {renderText(selectedMember.Description)}
        </Box>
      )}
    </>
  );
}

export default TeamComponent;
