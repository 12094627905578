import React from "react";
import { Typography, Stack, Chip } from "@mui/material";
function NewsHeaderComponent({
  date,
  tags,
  header,
  message,
  alignment = "left",
}) {
  return (
    <div>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ color: "white" }}
        textAlign={alignment}
      >
        {new Date(date).toISOString().split("T")[0]}

        <Stack direction="row" spacing={1} mb={2}>
          {tags.map((tag) => (
            <Chip
              key={tag.tagName}
              label={tag.tagName}
              size="small"
              sx={{ background: tag.tagColor.hex, color: "white" }}
            />
          ))}
        </Stack>
      </Typography>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: "white" }}
        textAlign={alignment}
      >
        {header}
      </Typography>
      <Typography sx={{ color: "white", mb: 4 }} textAlign={alignment}>
        {message}
      </Typography>
    </div>
  );
}

export default NewsHeaderComponent;
