import React from "react";

import { Grid, Container, Box } from "@mui/material";
import TextImageInfoComponent from "../../../shared/component/infoBlocks/textImage";
import HeroMessageComponent from "../../../shared/component/heroMessage";

import TextImageWithListComponent from "../../../shared/component/infoBlocks/textImageWithList";
import NumberedBlockComponent from "../../../shared/component/NumberedBlockComponent";
import principles from "../../../assets/data/GuidingPrinciples";
import InfoOnlyTextWithIconComponent from "../../../shared/component/infoBlocks/onlyTextWithIcon";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import BreadCrumbsComponent from "../../../shared/component/breadcrumbs/BreadCrumbComponent";
const breadcrumbItems = [
  { redirectUrl: "/", text: "Home" },
  { redirectUrl: "/company", text: "Company" },
];
const CompanyPage = () => {
  return (
    <div>
      <Box
        sx={{
          flexGrow: 1,
          height: { xs: "50vh", sm: "40vh", md: "30vh", lg: "30vh" },
          position: "relative",
        }}
      >
        {/* Overlay with tint */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "linear-gradient(45deg, #213765 30%, #1F4963 90%)",
          }}
        />
        <HeroMessageComponent message="Empowering Innovation, Enhancing Efficiency" />
      </Box>
      <BreadCrumbsComponent items={breadcrumbItems} />
      {/* MISSION */}
      <Box
        sx={{
          position: "relative",

          backgroundColor: "transparent",
          "&:before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "30%",
            bgcolor: "#EFEFFF", // Use your theme's primary color
            transform: "skewY(-10deg)", // Skew angle for the diagonal
            transformOrigin: "right",
            zIndex: -1, // Ensure the ribbon is behind the content
          },
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{ position: "relative", py: { xs: 5, sm: 5, md: 5, lg: 5 } }}
        >
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={8}>
            <TextImageInfoComponent
              imageToggle="Right"
              topHeader="WHAT ARE WE COMMITTED TO DO"
              middleHeader="Mission"
              text="At Enkiube Technologies, our mission is to help SMEs revolutionize their approach to digital transformation and eliminate their hidden costs through innovative, AI-driven solutions. We are dedicated to empowering businesses by enhancing their operational efficiency, streamlining documentation processes, and fostering a culture of continuous improvement. By providing intuitive, accessible tools and expert guidance, we aim to unlock the potential of every company we work with, making advanced technology an asset for all, not a privilege for the few."
              list={[]}
              buttonText="Learn more about our team"
              buttonGradient="linear-gradient(45deg, #9A9CF2 30%, #18A0FB 90%)"
              buttonLink="/company/team"
              imageLink="/icons/mission.png"
            />
          </Grid>
          <Grid item xs={0} md={2}></Grid>
        </Grid>
        {/* VISION */}
        <Grid
          container
          spacing={2}
          sx={{ position: "relative", py: { xs: 0, sm: 5, md: 5, lg: 10 } }}
        >
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={8}>
            <TextImageInfoComponent
              imageToggle="Left"
              topHeader="WHERE DO WE SEE OURSELVES?"
              middleHeader="Vision"
              text="Our vision is to lead the future of digital transformation for SMEs globally, where every business, regardless of size or sector, can access and leverage the power of AI and machine learning to redefine their operations. We envision a world where technology seamlessly integrates into the fabric of business processes, enhancing decision-making, productivity, and sustainability. Through our commitment to innovation, customer success, and the democratization of technology, Enkiube Technologies strives to be at the forefront of creating a more efficient, informed, and connected business ecosystem."
              list={[]}
              buttonText="Learn more about our strength"
              buttonGradient="linear-gradient(45deg, #9A9CF2 30%, #18A0FB 90%)"
              buttonLink="/platform/features"
              imageLink="/mockup2.png"
            />
          </Grid>
          <Grid item xs={0} md={2}></Grid>
        </Grid>
      </Box>
      {/* VALUES */}
      <Box sx={{ mb: { xs: 3, sm: 10, md: 10 } }}>
        <Grid container spacing={1}>
          <Grid item xs={0} md={2}></Grid>
          <Grid item xs={12} md={8}>
            <Grid
              container
              spacing={2}
              sx={{
                position: "relative",
                p: { xs: 0, sm: 0, md: 2 },
                background: "linear-gradient(90deg, #9A9CF2 10%, #18A0FB 90%)",
              }}
            >
              <Grid item xs={0} md={1}></Grid>
              <Grid item xs={0} md={10}>
                <TextImageWithListComponent
                  imageToggle="Left"
                  topHeader="WHAT DO WE CARE ABOUT"
                  middleHeader="Values"
                  list={[
                    {
                      header: "Innovation",
                      body: "We constantly push the boundaries of what's possible, integrating cutting-edge technology to solve real-world challenges.",
                    },
                    {
                      header: "Empowerment",
                      body: "Empowerment: Our core belief is in enabling our clients and their teams to excel, providing them with the tools and knowledge to drive their success.",
                    },
                    {
                      header: "Integrity",
                      body: " Transparency and honesty guide all our interactions, ensuring we build trust and lasting relationships with our partners and clients.",
                    },
                    {
                      header: "Sustainability",
                      body: "We are committed to developing solutions that not only benefit businesses but also contribute positively to the environment and society at large. We are strong advocates for AI integration into industry as an assistive tool and commit ourselves to helping customers mitigate the risk of job loss through skills development and training for the workforce to be an integral part of the change.",
                    },
                    {
                      header: "Collaboration",
                      body: "At the heart of our success is a collaborative culture that values every team member's contribution, fostering a workplace where innovation thrives through shared goals and mutual support.",
                    },
                  ]}
                  buttonText="Contact Us for More Info >"
                  buttonGradient="linear-gradient(45deg, #9A9CF2 30%, #18A0FB 90%)"
                  buttonLink="/contact-us"
                  hasButton={false}
                  textColor="#FFFFFF"
                  imageLink="/icons/diamond.png"
                />
              </Grid>
              <Grid item xs={0} md={1}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={0} md={2}></Grid>
        </Grid>

        {/* Guiding Principles */}
        <Container
          maxWidth="xl"
          sx={{ position: "relative", mt: { xs: 0, sm: 0, md: 10 } }}
        >
          <Grid container spacing={2}>
            <Grid item xs={0} sm={0} md={1}></Grid>
            <Grid item xs={12} sm={6} md={5}>
              <Grid item xs={12} sm={12} md={12}>
                <InfoOnlyTextWithIconComponent
                  topHeader="WHAT IS OUR NORTH STAR"
                  middleHeader="Our Guiding Principles"
                  text="Our guiding principles guide how we operate at Enkiube Technologies. They add clarity when we are evaluating and prioritizing between projects."
                  IconComponent={LightbulbOutlinedIcon}
                />
              </Grid>
              {principles.map(
                (principle, index) =>
                  index <= 2 && (
                    <Grid item xs={12} sm={12} md={12} key={index}>
                      <NumberedBlockComponent
                        gradient={principle.gradient}
                        number={principle.number}
                        title={principle.title}
                        text={principle.text}
                      />
                    </Grid>
                  )
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              sx={{
                position: "relative",
                p: { xs: 0, sm: 2, md: 4 },
                mt: { xs: 0, sm: 0, md: 2 },
              }}
            >
              {principles.map(
                (principle, index) =>
                  index > 2 && (
                    <Grid item xs={12} sm={12} md={12} key={index}>
                      <NumberedBlockComponent
                        gradient={principle.gradient}
                        number={principle.number}
                        title={principle.title}
                        text={principle.text}
                      />
                    </Grid>
                  )
              )}
            </Grid>
            <Grid item xs={0} sm={0} md={1}></Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default CompanyPage;
