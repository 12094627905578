import React, { useState } from "react";
import { Box, Typography, Button, Grid, TextField, Paper } from "@mui/material";
import { useDispatch } from "react-redux";
import { submitMainForm } from "../slices/submitFormsSlice";

const SignUpForm = ({ header }) => {
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState("");
  const [form, setForm] = useState({
    name: "",
    company: "",
    email: "",
    inquiryOption: "Generative AI for Businesses",
    message: "Home Page",
  });
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const validateGeneral = (form) => {
    if (!form["name"] || !form["company"]) {
      return false;
    }

    return true;
  };
  const onFormChangeHandler = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };
  const onFormSubmitHandler = (e) => {
    e.preventDefault();
    if (validateEmail(form.email) && validateGeneral(form)) {
      dispatch(submitMainForm(form));
      setFormMessage("Email sent successfully");
    } else {
      setFormMessage(
        "Please fill out all required fields and provide a valid email"
      );
    }
  };
  return (
    <Paper
      elevation={1}
      sx={{
        mb: { x: 0, sm: 0, md: 0, lg: 0 },
        p: 3,
        background:
          "linear-gradient(90deg, rgba(255,255,255,0.7) 10%, rgba(255,255,255,0.9) 90%)",
      }}
    >
      <Typography variant="h5" component="h2" gutterBottom sx={{ mb: 3 }}>
        {header}
      </Typography>
      <Box component="form" noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Name"
              id="name"
              name="name"
              variant="outlined"
              onChange={onFormChangeHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Company"
              id="company"
              name="company"
              variant="outlined"
              onChange={onFormChangeHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email"
              name="email"
              type="email"
              label="Email address"
              variant="outlined"
              onChange={onFormChangeHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={onFormSubmitHandler}
              fullWidth
            >
              Register
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default SignUpForm;
