// src/assets/data/menuItems.js

const palette = {
    "C0": "#FFFFFF",
    "C1": "#D9D9D9",
    "C2": "#9A9CF2",
    "C3": "#18A0FB",
    "C4": "#1F4963",
    "C5": "#2D2E80",
    "C6": "#213765",
    "C7": "#000000",    
    
}
  
  export default palette;
  