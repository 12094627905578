const pages = [
    {
      parent: "Home",
      children: [],
      address: "/",
    },
    {
      parent: "Company",
      children: [
        {
          name: "Mission, Vision, Values",
          address: "/company",
        },
        {
          name: "Who are we?",
          address: "/company/team",
        },
        {
          name: "News",
          address: "/company/news",
        },
      ],
    },
    {
      parent: "Platform",
      children: [
        {
          name: "Product Overview",
          address: "/platform",
        },
        {
          name: "Features",
          address: "/platform/features",
        },
      ],
    },
    {
      parent: "Solutions",
      children: [
        {
          name: "Manufacturing",
          address: "/solutions/manufacturing",
        },
        {
          name: "Regulatory & Compliance",
          address: "/solutions/auditors",
        },
        {
          name: "CDAP",
          address: "/solutions/cdap",
        },
      ],
    },
    {
      parent: "Resources",
      children: [
        {
          name: "FAQ",
          address: "/resources/faq",
        },
        {
          name: "Privacy Statement",
          address: "/resources/privacy",
        },
        {
          name: "Terms and Conditions",
          address: "/resources/terms",
        },
      ],
    },    
    {
      parent: "Contact us",
      children: [],
      address: "/contact-us",
    },
  ];

  export default pages;