const principles = [
    {
      gradient: "linear-gradient(0deg, #9A9CF2 30%, #18A0FB 90%)",
      number: '1',
      title: 'Customer-centric Innovation',
      text: 'Prioritize the needs and challenges of SMEs at the heart of product development, ensuring that solutions are not only technologically advanced but also practical and user-friendly.',
    },
    {
        gradient: "linear-gradient(0deg, #9A9CF2 30%, #18A0FB 90%)",
        number: '2',
        title: 'Ethical Technology Use',
        text: 'Commit to responsible AI, where ethical considerations guide the development and deployment of machine learning algorithms, protecting user data and privacy.',
    },
    {
        gradient: "linear-gradient(0deg, #9A9CF2 30%, #18A0FB 90%)",
        number: '3',
        title: 'Agility and Adaptability',
        text: 'Maintain a flexible approach, allowing for rapid response to technological advancements and market changes while ensuring solutions remain relevant and effective.',
    },
    {
        gradient: "linear-gradient(0deg, #9A9CF2 30%, #18A0FB 90%)",
        number: '4',
        title: 'Inclusive Growth',
        text: 'Support the growth of clients, employees, and the community, fostering an environment where success is shared and opportunities are created for all.',
    },
    {
        gradient: "linear-gradient(0deg, #9A9CF2 30%, #18A0FB 90%)",
        number: '5',
        title: 'Continuous Learning',
        text: 'Cultivate a culture of ongoing learning and curiosity, where feedback and data drive evolution and improvement in services, products, and processes.',
    },
    {
        gradient: "linear-gradient(0deg, #9A9CF2 30%, #18A0FB 90%)",
        number: '6',
        title: 'Collaborative Excellence',
        text: 'Encourage teamwork and partnerships that span disciplines and industries, believing that collective efforts lead to greater innovations and outcomes.',
    },
    {
        gradient: "linear-gradient(0deg, #9A9CF2 30%, #18A0FB 90%)",
        number: '7',
        title: 'Sustainability & Social Responsibility',
        text: 'Develop solutions that not only benefit businesses but also contribute to environmental sustainability and positive social impact.',
    }       
  ];

  export default principles