// HeaderComponent.js
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileHeader from "./mobileHeaderComponent";
import DesktopHeader from "./desktopHeaderComponent";

const HeaderComponent = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return <>{isMobile ? <MobileHeader /> : <DesktopHeader />}</>;
};

export default HeaderComponent;
