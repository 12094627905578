import React from "react";

import { Grid, Box } from "@mui/material";

import BreadCrumbsComponent from "../../../shared/component/breadcrumbs/BreadCrumbComponent";
import TextImageInfoComponent from "../../../shared/component/infoBlocks/textImage";
import TextImageWithListComponent from "../../../shared/component/infoBlocks/textImageWithList";
import PartnershipComponent from "../components/PartnershipComponent";
import partnerships from "../../../assets/data/partnerships";

const PlatformOverviewPage = () => {
  const breadcrumbItems = [
    { redirectUrl: "/", text: "Home" },
    { redirectUrl: "/platform", text: "Platform" },
  ];
  return (
    <div>
      <BreadCrumbsComponent items={breadcrumbItems} />
      <Box sx={{}}>
        <Grid
          container
          spacing={0}
          sx={{
            position: "relative",
            py: 0,
            my: 0,
          }}
        >
          <Grid item xs={0} md={2} sx={{}} />
          <Grid item xs={12} md={8} sx={{ px: 5 }}>
            <TextImageInfoComponent
              imageToggle="Left"
              topHeader="DIFFERENTIATE YOUR OPERATION"
              middleHeader="Quality Management Copilot Platform"
              text="Propel your quality management of your operation to the next level with integrating a co-pilot workflow management system."
              list={[]}
              buttonText="Contact us"
              buttonGradient="linear-gradient(45deg, #9A9CF2 30%, #18A0FB 90%)"
              buttonLink="/contact-us"
              imageLink="/mockup5.png"
              topMargin={2}
            />
          </Grid>
          <Grid item xs={0} md={2} sx={{}} />
          <Grid
            item
            xs={0}
            md={3}
            sx={{ background: "#eeeeee", my: { xs: 5, sm: 5, md: 5 } }}
          />
          <Grid
            item
            xs={12}
            md={6}
            sx={{ background: "#eeeeee", my: { xs: 5, sm: 5, md: 5 } }}
          >
            <TextImageInfoComponent
              imageToggle="Right"
              topHeader=""
              middleHeader="Bring visibility to your industrial workflow"
              text="Entity360 Quality-Management Co-pilot is equipped with an advanced Visual and Natural Language Capabilities. This superior functionality allows for users to find Quality Assurance documents, Work Instructions, TWI’s and SOPs far easier than common QMS platforms in the market. Imagine you can pull all the right information of a part on the shop-floor simply by showing the part to the camera."
              list={[]}
              haveButton={false}
              imageLink="snapshot2.png"
            />
          </Grid>
          <Grid
            item
            xs={0}
            md={3}
            sx={{ background: "#eeeeee", my: { xs: 5, sm: 5, md: 5 } }}
          />
          <Grid item xs={0} md={3} sx={{ mt: { xs: 2, sm: 2, md: 2 } }} />
          <Grid item xs={12} md={6} sx={{ mt: { xs: 2, sm: 2, md: 2 }, p: 5 }}>
            <TextImageInfoComponent
              imageToggle="Left"
              topHeader=""
              middleHeader="Automated Audit Events"
              text="Our Co-pilot platform is an AI-assisted tool to support Quality Assurance teams to perform audits. Whether day-to-day standard work or if you are preparing for an external audit, our platform simplifies the process of audit,  reporting, and analytics by:"
              list={[
                "Generating insights about performance of your audit",
                "Identifying and managing corrective actions",
                "Dynamic scheduling and audit",
                "Generating audit template based on your digital assets",
                "Tracking schedulede audits progress",
              ]}
              haveButton={false}
              imageLink="snapshot3.png"
              topMargin={0}
            />
          </Grid>
          <Grid item xs={0} md={3} sx={{ mt: { xs: 2, sm: 2, md: 2 } }} />

          {/* Generative AI Description */}
          <Grid item xs={0} md={3} />
          <Grid item xs={12} md={6}>
            <TextImageWithListComponent
              imageToggle="Left"
              topHeader=""
              middleHeader="Generative AI Meets Quality Assurance"
              list={[
                {
                  header: "::",
                  body: "Generative AI is sub-category of Artificial Intelligence capable of generating new content by learning from vast amount of existing examples and understanding patterns, styles and structures in the data. Running as the backbone of QMS Co-Pilot, this allows our platform to support your Quality Assurance and Process Improvement teams to:",
                },
                {
                  header: "-",
                  body: " Navigate through your organization’s body of knowledge faster, easier ",
                },
                {
                  header: "-",
                  body: "Identify root-causes of challenges by simply “talking” to your data!",
                },
                {
                  header: "-",
                  body: "Eliminate the pain of writing tedious reports. ",
                },
                {
                  header: "-",
                  body: "Leverage this technology to facilitate generating new Quality Management content.",
                },
              ]}
              hasButton={false}
              hasImage={false}
              textColor="#222222"
            />
          </Grid>
          <Grid item xs={0} md={3} />

          {/* Partnerships */}
          <Grid item xs={0} md={3} />
          <Grid
            item
            xs={12}
            md={6}
            sx={{ my: { xs: 2, sm: 2, md: 5 }, background: "#eeeeee" }}
          >
            <PartnershipComponent
              topHeader="PARTNERSHIPS"
              header="Who do we partner with?"
              buttonColorGradient="linear-gradient(45deg, #9A9CF2 30%, #18A0FB 90%)"
              hasButton={true}
              buttonText="Contact us for partnerhsrip"
              cardsData={partnerships}
              cardShadow="none"
              cardHeight="140"
            />
          </Grid>
          <Grid item xs={0} md={3} />
        </Grid>
      </Box>
    </div>
  );
};

export default PlatformOverviewPage;
