import React from "react";

import { Grid, Box, Typography, Button } from "@mui/material";

import BreadCrumbsComponent from "../../../shared/component/breadcrumbs/BreadCrumbComponent";

import FeatureComponent from "../components/featureComponent";
import audit from "../../../assets/data/audits";
import ProblemComponent from "../components/ProblemComponent";
import MultiTextImageInfoComponent from "../../../shared/component/infoBlocks/multiTextImage";
import NumberedBlockComponent from "../../../shared/component/NumberedBlockComponent";
import InfoOnlyTextWithIconComponent from "../../../shared/component/infoBlocks/onlyTextWithIcon";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
const ComplianceAuditPage = () => {
  const breadcrumbItems = [
    { redirectUrl: "/", text: "Home" },
    { redirectUrl: "/solutions/auditors", text: "Solutions" },
    { redirectUrl: "/solutions/auditors", text: "audit" },
  ];
  return (
    <div>
      <BreadCrumbsComponent items={breadcrumbItems} />
      <Box sx={{}}>
        <Grid
          container
          spacing={10}
          sx={{
            position: "relative",
            py: 0,
            mb: 10,
          }}
        >
          <Grid item xs={0} md={3} />
          <Grid item xs={12} md={8}>
            <FeatureComponent
              header={audit["feature"]["header"]}
              subHeader={audit["feature"]["subHeader"]}
              text={audit["feature"]["text"]}
              button1Gradient={audit["feature"]["buttons"][0]["buttonGradient"]}
              button1Text={audit["feature"]["buttons"][0]["buttonText"]}
              button1Link={audit["feature"]["buttons"][0]["buttonLink"]}
              button2Gradient={audit["feature"]["buttons"][1]["buttonGradient"]}
              button2Text={audit["feature"]["buttons"][1]["buttonText"]}
              button2Link={audit["feature"]["buttons"][1]["buttonLink"]}
              imageURL={audit["feature"]["imageUrl"]}
            />
          </Grid>
          <Grid item xs={0} md={1} />
        </Grid>
        <Grid
          container
          spacing={0}
          sx={{
            position: "relative",
            py: 0,
            my: 0,
            background: "#eeeeee",
          }}
        >
          {/* Partnerships */}
          <Grid item xs={0} md={3} />
          <Grid item xs={12} md={6} sx={{ my: { xs: 2, sm: 2, md: 2 } }}>
            <ProblemComponent
              topHeader="WHAT ARE WE TRYING TO SOLVE?"
              header="Problem Statement"
              buttonColorGradient="linear-gradient(45deg, #9A9CF2 30%, #18A0FB 90%)"
              hasButton={false}
              buttonText="Contact us for partnerhsrip"
              cardsData={audit["problems"]}
              cardShadow="none"
              cardHeight="140"
            />
          </Grid>
          <Grid item xs={0} md={3} />
        </Grid>
        <Grid container spacing={0} sx={{ my: { xs: 2, sm: 2, md: 10 } }}>
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={8}>
            <MultiTextImageInfoComponent
              imageToggle="Right"
              topHeader="HOW ARE WE TRYING TO SOLVE IT?"
              middleHeader="Solution Overview"
              moreText={true}
              text={[
                {
                  header: "",
                  paragraph: audit["solution"]["text"][0],
                },
                {
                  header: "",
                  paragraph: audit["solution"]["text"][1],
                },
                {
                  header: "",
                  paragraph: audit["solution"]["text"][2],
                },
              ]}
              haveButton={false}
              imageLink="/snapshot9.png"
              topMargin={5}
            />
          </Grid>
          <Grid item xs={0} md={2} />
        </Grid>
        <Box sx={{ zIndex: -1, height: 0, mt: -20 }}>
          <Typography
            textAlign="center"
            sx={{ fontSize: { xs: 200, md: 620 }, color: "#eeeeee" }}
          >
            <b>360</b>
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{ mb: { xs: 0, sm: 2, md: 25 }, mt: 10 }}
        >
          <Grid item xs={0} sm={0} md={1}></Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Grid item xs={12} sm={12} md={12}>
              <InfoOnlyTextWithIconComponent
                topHeader="WHAT BENEFITS DO WE PROVIDE?"
                middleHeader="Value Proposition"
                text=""
                IconComponent={LightbulbOutlinedIcon}
              />
            </Grid>
            {audit["value proposition"].map(
              (value_prop, index) =>
                index <= 2 && (
                  <Grid item xs={12} sm={12} md={12} key={index} sx={{ p: 1 }}>
                    <NumberedBlockComponent
                      gradient={value_prop.gradient}
                      number={value_prop.number}
                      title={value_prop.title}
                      text={value_prop.text}
                    />
                  </Grid>
                )
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={5}
            sx={{
              position: "relative",
              p: { xs: 0, sm: 0, md: 4 },
              mt: { xs: 0, sm: 0, md: 2 },
            }}
          >
            {audit["value proposition"].map(
              (audit, index) =>
                index > 2 && (
                  <Grid item xs={12} sm={12} md={12} key={index} sx={{ p: 1 }}>
                    <NumberedBlockComponent
                      gradient={audit.gradient}
                      number={audit.number}
                      title={audit.title}
                      text={audit.text}
                    />
                  </Grid>
                )
            )}
          </Grid>
          <Grid item xs={0} sm={0} md={1}></Grid>
          <Grid item xs={0} md={3} />
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography textAlign="center" variant="h6" sx={{ mb: 5, p: 2 }}>
              {audit["contact us"]["text"]}
            </Typography>
            <Button
              href={audit["contact us"]["buttonUrl"]}
              variant="contained"
              sx={{ background: audit["contact us"]["buttonColor"], mb: 5 }}
            >
              {audit["contact us"]["buttonText"]}
            </Button>
          </Grid>
          <Grid item xs={0} md={3} />
        </Grid>
      </Box>
    </div>
  );
};

export default ComplianceAuditPage;
