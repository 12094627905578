import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import BusinessGrantCard from "./GrantCardComponent";

const CDAPIntroComponent = ({
  topHeader,
  header,
  text,
  cards,
  cardShadow = "none",
  cardHeight = "140",
}) => {
  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Typography
        variant="overline"
        display="block"
        gutterBottom
        textAlign="center"
        color="#18A0FB"
      >
        {topHeader}
      </Typography>
      <Typography variant="h5" gutterBottom textAlign="center">
        {header}
      </Typography>
      <Typography variant="body1" gutterBottom textAlign="center">
        {text}
      </Typography>
      <Grid container spacing={10} sx={{ mt: 0, justifyContent: "center" }}>
        {cards.map((card, index) => (
          <Grid item xs={12} sm={6} md={5} lg={4} key={index}>
            <BusinessGrantCard
              image={card["imageUrl"]}
              header={card["title"]}
              text={card["text"]}
              hyperlinkText={card["buttonText"]}
              url={card["buttonLink"]}
            />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}></Box>
    </Box>
  );
};

export default CDAPIntroComponent;
