const manufacturing = {
    "feature":{
        "header":'Quality Assurance Co-pilot:',
        "subHeader":"Improving efficiency and compliance through GenAI Solutions",
        "text":"Our platform seamlessly integrates with your existing processes, streamlining documentation and audits, and reducing operational bottlenecks.",
        "imageUrl":"/snapshot8.webp",
        "bottomText": "By harnessing the power of AI, we ensure every step of your manufacturing line is optimized for accuracy, compliance, and speed, transforming challenges into opportunities for growth and innovation.",
        "buttons":[
            {
                "buttonText":"Learn More...",
                "buttonLink":"/platform",
                "buttonGradient":'rgba(0,0,0,1)'
            },
            {
                "buttonText":"Register for Trial Run...",
                "buttonLink":"/contact-us",
                "buttonGradient":'rgba(0,0,0,1)'
            }
        ]
    },
    "problems":[
        {
            "image":"/icons/documents.png",
            "header":"Documentation Challenges",
            "description":"Many manufacturers face hurdles in keeping documentation current, directly impacting compliance and operational efficiency. This leads to unnecessary costs and increased risk."   
        },
        {
            "image":"/icons/training.png",
            "header":"Resource and Training Gaps",
            "description":"The scarcity of QA resources and training limits proactive quality management, forcing companies into costly reactive measures for compliance and quality issues."   
        },
        {
            "image":"/icons/quality-control.png",
            "header":"Quality Assurance Overload",
            "description":"QA teams, critical in preventing defects, often operate with limited budgets, juggling manual tasks and struggling to meet quality standards efficiently."   
        }                
    ],
    "solution":{
        "text":"Our Quality Assurance Co-pilot has identified the opportunities for automation throughout the end-to-end workflow management cycles and adopted generative AI to save you cost and manpower hours to transition from reactive workflow management to proactive. Our solutions automate documentation management, streamline audits, and enhance workflow visibility enabling manufacturing to focus on production rather than paperwork.",
        "image":"/snapshot9.png"
    },
    "value proposition":[
        {
            "image":"/icons/dashboard.png",
            "header":"Increased Operational Efficiency",
            "description":"Automate routine checks and documentation updates, freeing up valuable resources for core manufacturing processes"   
        },
        {
            "image":"/icons/cost.png",
            "header":"Cost Savings",
            "description":"Reduce expenses associated with manual documentation management and traditional quality assurance methods"   
        },
        {
            "image":"/icons/compliant.png",
            "header":"Enhanced Compliance & Reduced Risks",
            "description":"Reduce expenses associated with manual documentation management and traditional quality assurance methods"   
        },
        {
            "image":"/icons/message.png",
            "header":"Closer Communication between all stakeholders",
            "description":"Facilitating live access to workflows across the organization and notification mechanism that provides two-way communication between all Quality Assurance stakeholders."   
        }        
    ],
    "contact us": {
        "text":"Discover how Entity360 QA Co-pilot can transform your manufacturing operations. Contact us today for a demo and see the difference for yourself!",
        "buttonColor": "#9A9CF2",
        "buttonText": "Contact our sales team...",
        "buttonUrl": "/contact-us"
    }

}

export default manufacturing