import React from "react";

import { Grid, Box, Typography } from "@mui/material";

import BreadCrumbsComponent from "../../../shared/component/breadcrumbs/BreadCrumbComponent";
import HeroImageV2Component from "../../../shared/component/heroImage/HeroImageV2Component";
import InfoOnlyTextComponent from "../../../shared/component/infoBlocks/onlyText";
import cdap from "../../../assets/data/cdap";
import CDAPIntroComponent from "../components/CDAPIntroComponent";
import ContactUs from "../../../shared/component/contacts/SmallContactUsComponent";

const breadcrumbItems = [
  { redirectUrl: "/", text: "Home" },
  { redirectUrl: "/solution/cdap", text: "Solution" },
  {
    redirectUrl: "/company/cdap",
    text: "CDAP",
  },
];

const CDAPPage = () => {
  return (
    <div>
      <Box
        sx={{
          flexGrow: 1,
          height: { md: "60vh", lg: "50vh" },
          position: "relative",
          padding: 2,
        }}
      >
        {/* Background video or image */}
        <HeroImageV2Component image="/snapshot11.png" />
        {/* Overlay with tint */}

        <Grid container spacing={2} sx={{ position: "relative", p: 8 }}>
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={3}>
            <InfoOnlyTextComponent
              header="Access Grants and Expertise to Transform Your Business"
              message=""
              buttonText="Register for Free Consultation"
              buttonLink="/contact-us"
              alignment="left"
            />
          </Grid>
          <Grid item xs={0} md={6} />
        </Grid>
      </Box>

      <BreadCrumbsComponent items={breadcrumbItems} />
      <Box
        sx={{
          position: "relative",
          backgroundColor: "transparent",
        }}
      >
        <Grid
          container
          spacing={10}
          sx={{ position: "relative", py: { xs: 0, sm: 5, md: 5, lg: 1 } }}
        >
          <Grid item xs={0} sm={1} md={3} lg={2} />
          <Grid
            item
            xs={12}
            sm={10}
            md={6}
            lg={8}
            sx={{ my: { xs: 0, sm: 2, md: 2 } }}
          >
            <CDAPIntroComponent
              topHeader={cdap["intro"]["topHeader"]}
              header={cdap["intro"]["header"]}
              text={cdap["intro"]["text"]}
              cards={cdap["intro"]["cards"]}
            />
          </Grid>
          <Grid item xs={0} sm={1} md={3} lg={2} />
          <Grid item xs={12} md={12}>
            <Typography
              component="h3"
              variant="h5"
              textAlign="center"
              sx={{
                lineHeight: { xs: "1.5", sm: "2.5", md: "2.5", lg: "2.5" },
                px: { xs: 5, sm: 5, md: 0, lg: 0 },
              }}
            >
              {cdap["contact us"]["callToAction"]}
            </Typography>
          </Grid>
          <Grid item xs={0} md={2} lg={2} xl={3} />
          <Grid item xs={0} md={4} lg={4} xl={3}>
            <Typography
              component="h3"
              variant="h6"
              sx={{ lineHeight: "2.5", px: { xs: 2, sm: 2, md: 0, lg: 0 } }}
              textAlign="center"
            >
              {cdap["contact us"]["text"]}
            </Typography>
          </Grid>
          <Grid item xs={0} sm={6} md={4} lg={4} xl={3}>
            <ContactUs />
          </Grid>

          <Grid item xs={0} md={2} lg={2} xl={3} />
        </Grid>
      </Box>
    </div>
  );
};

export default CDAPPage;
