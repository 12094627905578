import React, { useRef, useState } from "react";
import { Box } from "@mui/material";

const CirclesComponent = ({ bgColor = "grey.100" }) => {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 150,
          bottom: 0,
          left: 200,
          borderRadius: "1000px",
          bgcolor: bgColor,
          zIndex: -1,
          height: 200,
          width: 200,
          transformOrigin: "top left",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 150,
          bottom: 0,
          left: 500,
          borderRadius: "1000px",
          bgcolor: bgColor,
          zIndex: -1,
          height: 300,
          width: 300,
          transformOrigin: "top left",
        }}
      />
    </>
  );
};

export default CirclesComponent;
