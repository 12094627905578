import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const renderText = (text) => {
  // Replace <b> tags with <strong>
  const boldText = text.replace(/<b>(.*?)<\/b>/g, (_, p1) => (
    <strong>{p1}</strong>
  ));
  // Replace <p> tags with paragraph breaks
  const paragraphs = boldText.split(/<\/div><div>/g).map((paragraph, index) => (
    <Typography key={index} variant="body1" gutterBottom>
      {paragraph.replace(/<\/?div>/g, "")}
    </Typography>
  ));
  return paragraphs;
};

function CardComponent({
  imageSrc,
  imageWidthPercentage,
  header,
  text,
  textAlign = "center",
  imagePadding = "16px",
}) {
  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: imagePadding,
    boxShadow: "none",
    textAlign: textAlign,
  };

  const imageStyle = {
    width: `${imageWidthPercentage}%`,
    maxWidth: "100%", // Ensures the image is not larger than its natural size
    height: "auto", // Keeps the aspect ratio of the image
    marginBottom: "24px",
  };

  return (
    <Card sx={cardStyle}>
      <Box
        component="img"
        sx={imageStyle}
        src={imageSrc}
        alt="AI Integration"
      />
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          {header}
        </Typography>
        {renderText(text)}
      </CardContent>
    </Card>
  );
}

export default CardComponent;
