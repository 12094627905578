import React from "react";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";

const SingleColTextNoHeaderComponent = ({
  imageToggle,
  topHeader,
  middleHeader,
  text,
  list,
  buttonText,
  buttonGradient,
  buttonLink,
}) => {
  const isImageLeft = imageToggle === "Left";
  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate(buttonLink);
  };
  return (
    <Box sx={{ position: "relative", p: 4, color: "#FFFFFF" }}>
      <Typography color="primary" gutterBottom>
        {topHeader}
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        {middleHeader}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {text}
      </Typography>
      <List>
        {list.map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon sx={{ m: 0, p: 0 }}>
              <CheckIcon
                sx={{
                  position: "relative",
                  p: 0,
                  m: 0,
                  color: "#FFFFFF",
                }}
              />
            </ListItemIcon>
            <span>
              <b>{item["header"]}:</b>
              {item["body"]}
            </span>
          </ListItem>
        ))}
      </List>
      <Button
        onClick={onClickHandler}
        variant="contained"
        sx={{
          backgroundImage: buttonGradient,
          ":hover": {
            // Add hover effect
            backgroundImage: buttonGradient,
          },
        }}
        href={buttonLink}
      >
        {buttonText}
      </Button>
    </Box>
  );
};

export default SingleColTextNoHeaderComponent;
