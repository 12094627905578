const teamMembers = [
    {
        "Name": "Sina",
        "FamilyName": "Khosravi",
        "Title": "CEO and Founder",
        "SocialMedia":"https://www.linkedin.com/in/sinakhosravi/",
        "imageLink": "/sinaKMaram.jpg",
        "Description": "Sina Khosravi is a Queen’s University graduate and the founder of Enkiube Technologies. Sina’s career evolved as a leader of Continuous Process Improvement in manufacturing sector to enterprise-level Machine Learning Engineer and AI integrator working with names such as Marmon Rails, Validere and ApplyBoard.",
    },
    // {
    //     "Name": "Amir",
    //     "FamilyName": "Tahmasebi",
    //     "Title":"Technology Advisor",
    //     "SocialMedia":"https://www.linkedin.com/in/amirtahmasebi/",
    //     "imageLink": "/amirTahmasebi.jfif",
    //     "Description": "Dr.Amir Tahmasebi is a distinguished leader with over a decade of experience in research and development, specializing in data science, machine learning, and natural language processing across various industries. As the Director of Data Science at BD, a leading global technology company, he spearheads a team of skilled data scientists and engineers dedicated to developing innovative solutions that enhance patient care, reduce healthcare costs, and improve clinical processes. Dr. Tahmasebi's expertise is grounded in creating and applying advanced AI and NLP models for the comprehensive analysis of multimodal data. His leadership has culminated in numerous cross-functional project successes, earning him several innovation transfer awards, patents, and scholarly publications. Furthermore, Dr. Tahmasebi is committed to education and mentorship, having taught and guided many students and researchers at top-tier universities and institutions. His overarching goal is to harness the power of data science and artificial intelligence to revolutionize healthcare informatics and transform the delivery of healthcare services, making him a pivotal figure in pushing the boundaries of technology application in healthcare and beyond."
    // },
    {
        "Name": "Reyhan",
        "FamilyName": "Muhammad",
        "Title":"Fullstack Developer",
        "SocialMedia":"https://www.linkedin.com/in/reyhanmohammed/",
        "imageLink": "https://media.licdn.com/dms/image/D4D03AQFy5oXKbPLLEQ/profile-displayphoto-shrink_800_800/0/1683757979478?e=1714003200&v=beta&t=XCvxTseizY_pmNN2Hpl9CC1XPyyMGkuDYym-Du6eu70",
        "Description": "Reyhan Muhammad is our Fullstack Developer. He is a proficient Web Developer, known for his expertise in ReactJS with Redux, MicroFrontend Architecture using SingleSPA, NodeJS with Express, and database management in both SQL and MongoDB using Mongoose. He possesses substantial knowledge of AWS services, including S3, Lambda, API Gateway, DynamoDB, and Cognito, making him a valuable asset to any team aiming to innovate and excel in the digital space."
    },
    {
        "Name": "Chiranjibi",
        "FamilyName": "Karki",
        "Title":"Software Engineer",
        "SocialMedia":"https://www.linkedin.com/in/reyhanmohammed/",
        "imageLink": "https://media.licdn.com/dms/image/C5603AQF5NKQw554-Aw/profile-displayphoto-shrink_800_800/0/1629135588798?e=1714003200&v=beta&t=YqdukdfBehG3ukLmvwZS-kHfM1vmK8LV5l2i028o99Y",
        "Description": "Chiranjibi is a skilled Front-end Developer with specialized expertise in React and Redux. He brings a wealth of knowledge in leveraging advanced front-end frameworks, including D3 and react-graph-force, to create dynamic and responsive web applications. His technical proficiency and innovative approach make him a valuable asset to our team, consistently pushing the boundaries of web development to deliver exceptional user experiences."
    }    
]

  
  export default teamMembers;

  