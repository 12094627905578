import React from "react";
import { Grid, Container, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import questionAnswer from "../../../assets/data/questionAnswer";
import BreadCrumbsComponent from "../../../shared/component/breadcrumbs/BreadCrumbComponent";
import CirclesComponent from "../../../shared/component/backgrounds/CirclesComponent";

const breadcrumbItems = [
  { redirectUrl: "/", text: "Home" },
  { redirectUrl: "/resources/qa", text: "Questions and Answers" },
];

const FAQ = () => {
  return (
    <Box>
      <BreadCrumbsComponent items={breadcrumbItems} />
      <CirclesComponent />
      <Grid container>
        <Grid item xs={0} sm={1} md={2}></Grid>
        <Grid item xs={0} sm={10} md={8}>
          <Typography variant="h4" component="h1" gutterBottom>
            Frequently Asked Questions
          </Typography>
        </Grid>
        <Grid item xs={0} sm={1} md={2}></Grid>
        <Grid item xs={0} sm={1} md={2}></Grid>
        <Grid item xs={0} sm={1} md={8}>
          <Grid container spacing={10}>
            {/* First Column */}
            <Grid item xs={12} sm={6}>
              <List>
                {questionAnswer
                  .slice(0, Math.ceil(questionAnswer.length / 2))
                  .map((qa, index) => (
                    <React.Fragment key={index}>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={qa.question}
                          secondary={
                            <Typography
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {qa.answer}
                            </Typography>
                          }
                          primaryTypographyProps={{ fontWeight: "bold" }}
                        />
                      </ListItem>
                      {index < Math.ceil(questionAnswer.length / 2) - 1 && (
                        <Divider />
                      )}
                    </React.Fragment>
                  ))}
              </List>
            </Grid>

            {/* Second Column */}
            <Grid item xs={12} sm={6}>
              <List>
                {questionAnswer
                  .slice(Math.ceil(questionAnswer.length / 2))
                  .map((qa, index) => (
                    <React.Fragment key={index}>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={qa.question}
                          secondary={
                            <Typography
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {qa.answer}
                            </Typography>
                          }
                          primaryTypographyProps={{ fontWeight: "bold" }}
                        />
                      </ListItem>
                      {index <
                        questionAnswer.length -
                          Math.ceil(questionAnswer.length / 2) -
                          1 && <Divider />}
                    </React.Fragment>
                  ))}
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0} sm={1} md={2}></Grid>
      </Grid>
    </Box>
  );
};

export default FAQ;
