const partnerships=[
    {
        image: "/icons/brainstorming.png",
        header: "Consulting Firms",
        description: "Business Management and Process Improvement consulting companies seeking to enhance their service package to their customers",
      },
      {
        image: "/icons/auditor.png",
        header: "External Auditors",
        description: "If your company does external audits particularly in the area of Quality Management Systems and ISO-9001",
      },
      {
        image: "/icons/technology.png",
        header: "CDAP Advisors",
        description: "If you are a registered CDAP advisor by Innovation, Science, Economic Development Canada working on Digital Adoption plan for your industrial customers",
      }      
]
export default partnerships