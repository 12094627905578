import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Box, Typography } from "@mui/material";
import NewsCardV1Component from "../../../shared/component/cards/NewsCardV1Components";
import "react-multi-carousel/lib/styles.css";
import "../../../assets/css/carouselStyles.css";
import { useSelector } from "react-redux";
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const NewsCarouselComponent = () => {
  const baseUrl = `https://cdn.sanity.io/images/${process.env.REACT_APP_SANITY_PROJECT_ID}/${process.env.REACT_APP_DATASET_NAME}/`;
  const newsItems = useSelector((state) => state.articles.newsArticles);

  return (
    <Box>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ textAlign: "center", my: 2 }}
      >
        <b>OUR NEWS</b>
      </Typography>
      <Carousel
        showDots
        responsive={responsive}
        ssr={true} // Means to render carousel on server-side.
        infinite={false}
        draggable={true}
        keyBoardControl={true}
        customTransition="transform 300ms ease-in-out"
        transitionDuration={500}
        containerClass="custom-carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-margin"
      >
        {newsItems.map((item, index) => (
          <NewsCardV1Component
            key={index}
            headerImageUrl={item.headerImageUrl.asset.url}
            avatarImageUrl={`${baseUrl}${item.authorImage.asset._ref}`
              .replace("image-", "")
              .replace(/-(\w+)$/, ".$1")}
            titleText={item.titleText}
            subtitle={item.subTitle}
            readMoreUrl={`/company/news/${item.slug.current}`}
          />
        ))}
      </Carousel>
    </Box>
  );
};

export default NewsCarouselComponent;
