import React from "react";
import { Box } from "@mui/material";

const HeroImageV1Component = ({ image }) => {
  return (
    <Box
      sx={{
        position: "absolute", // Use 'absolute' or 'fixed' depending on your needs
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -1, // Ensure the video is behind other content,
        alignContent: "right",
        alignItems: "right",
        background: "#2D2E80",
        "&::after": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)", // Black tint overlay
        },
      }}
    >
      <img
        src={image}
        alt="Background"
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover", // Ensures the image covers the area without distortion
          zIndex: 0, // Ensure the image is behind the overlay
        }}
      />
    </Box>
  );
};

export default HeroImageV1Component;
