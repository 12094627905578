import React from "react";
import { Grid, Box } from "@mui/material";
import Typography from "@mui/material/Typography";

import BreadCrumbsComponent from "../../../shared/component/breadcrumbs/BreadCrumbComponent";
import CirclesComponent from "../../../shared/component/backgrounds/CirclesComponent";

const breadcrumbItems = [
  { redirectUrl: "/", text: "Home" },
  { redirectUrl: "/resources/terms", text: "Terms and Conditions" },
];

const TermsConditionsPage = () => {
  return (
    <Box>
      <BreadCrumbsComponent items={breadcrumbItems} />
      <CirclesComponent />
      <Grid container sx={{ position: "relative", mb: 20 }}>
        <Grid item xs={0} sm={1} md={2}></Grid>
        <Grid item xs={0} sm={10} md={8}>
          <Typography variant="h4" component="p" gutterBottom>
            Terms and Conditions
          </Typography>
        </Grid>
        <Grid item xs={0} sm={1} md={2}></Grid>
        <Grid item xs={0} sm={1} md={2}></Grid>
        <Grid item xs={0} sm={1} md={10}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid item xs={0} sm={1} md={2}></Grid>
              <Grid item xs={12} sm={10} md={8}>
                <Typography paragraph>
                  By accessing and using the Entity360 platform, users are
                  granted a non-exclusive, non-transferable, revocable license
                  to use the software for internal business purposes only,
                  subject to these Terms and Conditions.
                </Typography>
                <Typography paragraph>Users are prohibited from:</Typography>
                <Typography component="ul">
                  <li>
                    Modifying, distributing, or creating derivative works based
                    on the Entity360 platform without prior written consent from
                    Enkiube Technologies.
                  </li>
                  <li>
                    Using the platform for any unlawful purpose or in a manner
                    that could damage, disable, overburden, or impair any
                    server, or the network(s) connected to any server, or
                    interfere with any other party's use and enjoyment of the
                    platform.
                  </li>
                  <li>
                    Attempting to gain unauthorized access to any services,
                    accounts, computer systems, or networks connected to any
                    server through hacking, password mining, or any other means.
                  </li>
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Account Registration and Management
                </Typography>
                <Typography paragraph>
                  Users are required to register for an account to access the
                  platform. Account registration requires users to provide
                  accurate, current, and complete information as prompted by the
                  registration form. Users are responsible for maintaining the
                  confidentiality of their account information, including the
                  password, and for all activities that occur under their
                  account.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Intellectual Property Rights
                </Typography>
                <Typography paragraph>
                  Enkiube Technologies and/or its licensors retain all
                  proprietary rights to the Entity360 platform, including
                  copyrights, trademarks, patents, and trade secrets. The
                  platform and all content therein, including software, logos,
                  and all other material, are protected by law. Unauthorized use
                  of the platform's content may violate these laws and could
                  result in civil and criminal penalties.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Liability Limitations
                </Typography>
                <Typography paragraph>
                  Enkiube Technologies will not be liable for any direct,
                  indirect, incidental, special, consequential, or exemplary
                  damages resulting from the use or the inability to use the
                  platform or any services provided. This limitation of
                  liability applies to the fullest extent permitted by law in
                  the applicable jurisdiction.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Payment Terms
                </Typography>
                <Typography paragraph>
                  Subscriptions to the Entity360 platform are billed on a
                  per-user, per-month basis. All fees are due at the beginning
                  of the subscription period and are non-refundable, except as
                  required by law or as explicitly stated in these Terms and
                  Conditions.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Termination of Service
                </Typography>
                <Typography paragraph>
                  Enkiube Technologies reserves the right to terminate or
                  suspend access to the Entity360 platform for any user who
                  violates these Terms and Conditions. Users may terminate their
                  subscription at any time by providing written notice to
                  Enkiube Technologies.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Dispute Resolution
                </Typography>
                <Typography paragraph>
                  Any disputes arising out of or related to these Terms and
                  Conditions or the use of the Entity360 platform will be
                  resolved through binding arbitration, conducted by the rules
                  of a recognized arbitration association, unless otherwise
                  required by law.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Contact Information
                </Typography>
                <Typography paragraph>
                  For any questions, concerns, or notices related to these Terms
                  and Conditions, please contact us at info@enkiube.com
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0} sm={1} md={2}></Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TermsConditionsPage;
