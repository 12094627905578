import React, { useState } from "react";

import { Grid, Box } from "@mui/material";

import BreadCrumbsComponent from "../../../shared/component/breadcrumbs/BreadCrumbComponent";
import HeroImageV1Component from "../../../shared/component/heroImage/HeroImageV1Component";
import InfoOnlyTextComponent from "../../../shared/component/infoBlocks/onlyText";
import AllNewsComponent from "../components/AllNewsComponent";
import { useSelector } from "react-redux";

const breadcrumbItems = [
  { redirectUrl: "/", text: "Home" },
  { redirectUrl: "/company", text: "Company" },
  { redirectUrl: "/company/news", text: "News" },
];

const NewsPage = () => {
  const newsArticles = useSelector((state) => state.articles.newsArticles);
  return (
    <div>
      <Box
        sx={{
          flexGrow: 1,
          height: { xs: "80vh", sm: "40vh", md: "30vh", lg: "30vh" },
          position: "relative",
        }}
      >
        {/* Background video or image */}
        <HeroImageV1Component image="/News.jpg" />
        {/* Overlay with tint */}

        <Grid
          container
          spacing={2}
          sx={{ position: "relative", p: { xs: 5, sm: 5, md: 8, lg: 8 } }}
        >
          <Grid item xs={0} md={1} />
          {/* Left side with header and message */}
          <Grid item xs={12} md={12}>
            <InfoOnlyTextComponent
              header="For those who value empowering workforce"
              message="We pioneer co-pilot workflow management"
              buttonText="Discover more"
              buttonLink="/solutions/manufacturing"
              alignment="center"
            />
          </Grid>
          <Grid item xs={0} md={1} />
        </Grid>
      </Box>

      <BreadCrumbsComponent items={breadcrumbItems} />
      <Box
        sx={{
          position: "relative",
          backgroundColor: "transparent",
          "&:before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "30%",
            bgcolor: "#EFEFFF", // Use your theme's primary color
            transform: "skewY(-10deg)", // Skew angle for the diagonal
            transformOrigin: "right",
            zIndex: -1, // Ensure the ribbon is behind the content
          },
        }}
      >
        <Grid
          container
          spacing={1}
          sx={{ position: "relative", p: { xs: 5, sm: 5, md: 5 } }}
        >
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={8}>
            <AllNewsComponent newsItems={newsArticles} />
          </Grid>
          <Grid item xs={0} md={2}></Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default NewsPage;
