// Import your configured Sanity client
import sanityClient from '../utils/sanityClient'

// A function to fetch news articles from Sanity
export const getNewsArticlesService = async () => {
    const query = `*[_type == "newsArticle"]{
        titleText,
        subTitle,
        slug,
        "authorName": author->name,
        "authorImage": author->avatarImage,
        date,
        text,
        "tags": tags[]->{tagName, tagColor},
        headerImageUrl {
          asset->{
            _id,
            url
          },
          alt
        }
    }`;
    return await sanityClient.fetch(query);

}
