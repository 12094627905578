import React, { useEffect } from "react";

import { Grid, Box, Typography, Chip, Stack } from "@mui/material";

import BreadCrumbsComponent from "../../../shared/component/breadcrumbs/BreadCrumbComponent";
import HeroImageV1Component from "../../../shared/component/heroImage/HeroImageV1Component";

import { useParams } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";
import NewsHeaderComponent from "../components/NewsHeaderComponent";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { IconButton } from "@mui/material";
import NewsCarouselComponent from "../components/CarouselComponent";
import { useSelector } from "react-redux";

const SingleNewsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let { slug } = useParams();
  const newsArticles = useSelector((state) => state.articles.newsArticles);
  let newsItem = newsArticles.find((item) => item.slug.current === slug);
  const breadcrumbItems = [
    { redirectUrl: "/", text: "Home" },
    { redirectUrl: "/company", text: "Company" },
    { redirectUrl: "/company/news", text: "News" },
    {
      redirectUrl: `/company/news/${newsItem.slug}`,
      text: newsItem.titleText,
    },
  ];
  const url = `https://www.enkiube.com/company/news/{newsItem.slug}`;
  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(newsItem.titleText);

  const socialNetworks = {
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
    twitter: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`,
  };
  return (
    <div>
      <Box
        sx={{
          flexGrow: 1,
          height: { xs: "80vh", sm: "40vh", md: "30vh", lg: "30vh" },
          position: "relative",
        }}
      >
        {/* Background video or image */}
        <HeroImageV1Component image={newsItem.headerImageUrl.asset.url} />
        {/* Overlay with tint */}

        <Grid container spacing={2} sx={{ position: "relative", p: 8 }}>
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={8}>
            <NewsHeaderComponent
              tags={newsItem.tags}
              date={newsItem.date}
              header={newsItem.titleText}
              message={newsItem.subtitle}
            />
          </Grid>
          <Grid item xs={0} md={2} />
        </Grid>
      </Box>

      <BreadCrumbsComponent items={breadcrumbItems} />
      <Box
        sx={{
          position: "relative",
          backgroundColor: "transparent",
          "&:before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "30%",
            bgcolor: "#EFEFFF", // Use your theme's primary color
            transform: "skewY(-10deg)", // Skew angle for the diagonal
            transformOrigin: "right",
            zIndex: -1, // Ensure the ribbon is behind the content
          },
        }}
      >
        <Grid
          order={1}
          container
          spacing={{ xs: 0, sm: 0, md: 10, lg: 10 }}
          sx={{
            position: "relative",
            py: { xs: 0, sm: 5, md: 5, lg: 0 },
            px: { xs: 0, sm: 5, md: 0, lg: 0 },
          }}
        >
          <Grid
            item
            xs={0}
            sm={0}
            md={2}
            lg={2}
            order={{ xs: 2, sm: 2, md: 3, lg: 3 }}
          />
          <Grid
            item
            xs={0}
            sm={12}
            md={5}
            lg={5}
            order={{ xs: 2, sm: 2, md: 1, lg: 1 }}
            sx={{ px: 5 }}
          >
            <BlockContent blocks={newsItem.text} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            sx={{ mb: { xs: 5, sm: 5, md: 55 }, px: 5 }}
            order={{ xs: 1, sm: 1, md: 2, lg: 2 }}
          >
            <Typography variant="h6" gutterBottom>
              <div>
                <span>
                  Share this news:
                  <br />
                </span>
                <IconButton
                  aria-label="share on LinkedIn"
                  onClick={() => window.open(socialNetworks.linkedin, "_blank")}
                >
                  <LinkedInIcon />
                </IconButton>
                <IconButton
                  aria-label="share on Facebook"
                  onClick={() => window.open(socialNetworks.facebook, "_blank")}
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  aria-label="share on Twitter"
                  onClick={() => window.open(socialNetworks.twitter, "_blank")}
                >
                  <TwitterIcon />
                </IconButton>
              </div>
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ my: { xs: 2, md: 10 } }}
            >
              <div>
                <span>
                  Keywords:
                  <br />
                </span>
                <Stack direction="row" spacing={1} mb={2}>
                  {newsItem.tags.map((tag) => (
                    <Chip
                      key={tag.tagName}
                      label={tag.tagName}
                      size="small"
                      sx={{ background: tag.tagColor.hex }}
                    />
                  ))}
                </Stack>
              </div>
            </Typography>
          </Grid>
          <Grid item xs={0} sm={0} md={2} lg={2}></Grid>
          <Grid
            order={2}
            container
            sx={{
              backgroundColor: "#eeeeee",
              padding: "20px",
              my: { xs: 3, md: 4 },
            }}
          >
            <Grid item xs={0} sm={0} md={2} lg={2}></Grid>
            <Grid item xs={12} sm={0} md={8} lg={8}>
              <NewsCarouselComponent />
            </Grid>
            <Grid item xs={0} sm={0} md={2} lg={2}></Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SingleNewsPage;
