import React from "react";
import { Box, Typography, Button, createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const SingleColTextWithHeaderComponent = ({
  imageToggle,
  topHeader,
  middleHeader,
  text,
  buttonText,
  buttonGradient,
  buttonLink,
  textColor,
  lineHeight = "2.5",
  buttonType = "edge",
}) => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate(buttonLink);
  };
  const theme = ({ lineHeight }) => {
    return createTheme({
      typography: {
        body1: {
          lineHeight: lineHeight,
        },
      },
    });
  };
  const isImageLeft = imageToggle === "Left";
  const buttonRound = buttonType === "round";

  return (
    <Box
      sx={{
        position: "relative",
        p: { xs: 1, sm: 1, md: 4 },
        py: { xs: 10, sm: 0, md: 0 },
        color: textColor,
      }}
    >
      <Typography color="primary" gutterBottom>
        {topHeader}
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        {middleHeader}
      </Typography>
      <ThemeProvider theme={theme({ lineHeight })}>
        <Typography variant="body1" component="h2" gutterBottom>
          {text}
        </Typography>
      </ThemeProvider>
      <Button
        onClick={onClickHandler}
        variant="contained"
        sx={{
          mt: { xs: 0, sm: 0, md: 2 },
          backgroundImage: buttonGradient,
          borderRadius: buttonRound ? 100 : 0,
          ":hover": {
            // Add hover effect
            backgroundImage: buttonGradient,
          },
        }}
        href={buttonLink}
      >
        <span style={{ color: textColor }}>{buttonText}</span>
      </Button>
    </Box>
  );
};

export default SingleColTextWithHeaderComponent;
