import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const TextImageWithListComponent = ({
  imageToggle,
  topHeader,
  middleHeader,
  text,
  list,
  buttonText,
  buttonGradient,
  buttonLink,
  imageLink,
  hasButton,
  hasImage = true,
  textColor,
}) => {
  const isImageLeft = imageToggle === "Left";
  let width = 10;
  if (hasImage !== true) {
    width = 12;
  }
  return (
    <Box sx={{ pl: { xs: 2, sm: 0, md: 0, lg: 0 }, my: 2, color: textColor }}>
      <Grid className="montserrat" container spacing={10} alignItems="center">
        <Grid
          item
          xs={12}
          md={width}
          order={{ xs: 2, md: isImageLeft ? 1 : 2 }}
        >
          <Box>
            <Typography color="primary" gutterBottom>
              {topHeader}
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
              {middleHeader}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {text}
            </Typography>
            <List>
              {list.map((item, index) => (
                <ListItem key={index}>
                  <ListItemIcon
                    sx={{
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        lg: "flex",
                      },
                      m: 0,
                      p: 0,
                    }}
                  >
                    <CheckIcon
                      sx={{
                        position: "relative",
                        p: 0,
                        m: 0,
                        color: "#FFFFFF",
                      }}
                    />
                  </ListItemIcon>
                  <span>
                    <b>{item["header"]}: </b>
                    {item["body"]}
                  </span>
                </ListItem>
              ))}
            </List>
            {hasButton && (
              <Button
                variant="contained"
                sx={{
                  backgroundImage: buttonGradient,
                  ":hover": { backgroundImage: buttonGradient },
                }}
                href={buttonLink}
              >
                {buttonText}
              </Button>
            )}
          </Box>
        </Grid>
        {hasImage && (
          <Grid
            item
            xs={12}
            md={2}
            order={{ xs: 1, md: isImageLeft ? 2 : 1 }}
            sx={{ mx: { xs: 10, sm: 5, md: 0, lg: 0 } }}
          >
            <img
              src={imageLink}
              alt="Description"
              style={{ width: "100%", height: "auto" }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default TextImageWithListComponent;
