import React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";

const MultiTextImageInfoComponent = ({
  imageToggle,
  topHeader,
  middleHeader,
  text,
  buttonText,
  buttonGradient,
  buttonLink,
  imageLink,
  haveButton = true,
  topMargin = 4,
  moreText = false,
}) => {
  const isImageLeft = imageToggle === "Left";
  const textWidth = moreText ? 7 : 6;

  return (
    <Box sx={{ p: topMargin - 2, my: topMargin }}>
      <Grid className="montserrat" container spacing={10} alignItems="center">
        <Grid
          item
          xs={12}
          md={textWidth}
          order={{ xs: 2, md: isImageLeft ? 1 : 2 }}
        >
          <Box>
            <Typography color="primary" gutterBottom>
              {topHeader}
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
              {middleHeader}
            </Typography>
            <Typography variant="body1" gutterBottom component="div">
              {text.map((item, index) => (
                <React.Fragment key={index}>
                  <b>{item.header}</b>
                  {/* Use div instead of p for paragraphs to avoid nesting p tags */}
                  <div>{item.paragraph}</div>
                </React.Fragment>
              ))}
            </Typography>
            {haveButton && (
              <Button
                variant="contained"
                sx={{
                  backgroundImage: buttonGradient,
                  ":hover": {
                    // Add hover effect
                    backgroundImage: buttonGradient,
                  },
                }}
                href={buttonLink}
              >
                {buttonText}
              </Button>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12 - textWidth}
          order={{ xs: 1, md: isImageLeft ? 2 : 1 }}
        >
          <img
            src={imageLink}
            alt="Description"
            style={{ width: "100%", height: "auto" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MultiTextImageInfoComponent;
