import React from "react";
import { Typography } from "@mui/material";
function HeroMessageComponent({
  message,
  variant = "h2",
  component = "h1",
  fontSize = {
    xs: "2.5rem",
    sm: "2.5rem",
    md: "3rem",
    lg: "2.5rem",
  },
}) {
  return (
    <Typography
      position="relative"
      variant={variant}
      component={component}
      gutterBottom
      sx={{
        textAlign: { xs: "center", sm: "center" },
        pt: { xs: 5, sm: 15, md: 15, lg: 15 },
        fontSize,

        color: "white",
      }}
    >
      <span className="comfortaa">{message}</span>
    </Typography>
  );
}

export default HeroMessageComponent;
