import React from "react";
import { SvgIcon } from "@mui/material";
import { ReactComponent as LogoSVG } from "../../assets/icons/logo7.svg";

const LogoIcon = ({
  width = "96px",
  height = "96px",
  fill = "#333333", // Assuming this is the stroke color you want
  stroke = "#FFFFFF", // White stroke
  sx = {},
}) => {
  return (
    <SvgIcon
      component={LogoSVG}
      sx={{
        width,
        height,
        color: "#ffffff", // This applies to the stroke if your SVG uses currentColor
        "& path": {
          fill,
          stroke,
        },
        ...sx,
      }}
      viewBox="330 -150 40 1040" // This should match your SVG's viewBox
    />
  );
};

export default LogoIcon;
