import axios from "axios";

export const submitMainFormService=(data)=>{
    const url_contact_us = process.env.REACT_APP_API_GATEWAY_URL+"/contact-us";

    return axios.post(
        url_contact_us,
        data, 
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
}
