import React from "react";
import { Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
function InfoOnlyTextComponent({
  header,
  headerVariant = "h4",
  messageVariant = "body1",
  message,
  buttonText,
  buttonLink,
  hasButton = true,
  alignment = "left",
}) {
  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate(buttonLink);
  };
  return (
    <div>
      <Typography
        gutterBottom
        sx={{ color: "white", typography: headerVariant }}
        textAlign={alignment}
      >
        {header}
      </Typography>
      <Typography
        sx={{ color: "white", mb: 4, typography: messageVariant }}
        textAlign={alignment}
      >
        {message}
        <br />
        {hasButton && (
          <Button
            onClick={onClickHandler}
            variant="contained"
            sx={{ bgcolor: "primary.main", my: { xs: 5, md: 5 } }}
            textAlign={alignment}
          >
            {buttonText}
          </Button>
        )}
      </Typography>
    </div>
  );
}

export default InfoOnlyTextComponent;
