const questionAnswer = [
    {
      "question": "What is Entity360?",
      "answer": "Entity360 is an Integrated Audit and Compliance (IAAC) platform designed to streamline workflow management and training for companies, leveraging advanced technologies like GenAI for audit generation, natural language search, and object detection to enhance operational efficiency."
    },
    {
      "question": "How can Entity360 improve my company's operational efficiency?",
      "answer": "By automating audits, simplifying documentation access through natural language and visual searches, and keeping your workflows and training materials updated and centralized, Entity360 significantly reduces manual processing time and costs."
    },
    {
      "question": "What makes Entity360 different from traditional Quality Management Systems?",
      "answer": "Unlike traditional systems, Entity360 offers a more dynamic, user-friendly approach with features like GenAI for automated audit creation, visual search for easy document retrieval, and real-time updates to documentation, tailored specifically for modern workflow challenges."
    },
    {
      "question": "Can Entity360 integrate with my current Quality Management System?",
      "answer": "While direct integration may not be available now, Entity360 is designed to complement existing systems by providing additional layers of efficiency and accessibility. We're also exploring future integrations to enhance compatibility."
    },
    {
      "question": "How does the object detection tool work?",
      "answer": "The object detection tool in Entity360 allows users to show a part to the camera, and the system will automatically pull up relevant information, streamlining the process of finding specifications, safety instructions, and other essential data."
    },
    {
      "question": "What is the CDAP Advisory title, and how does it benefit my company?",
      "answer": "The CDAP Advisory title enables Enkiube Technologies to provide consulting services to companies at nearly zero cost, facilitating digital transformation and process improvement through access to CDAP grants and tailored advice."
    },
    {
      "question": "How does Entity360 facilitate compliance and audits?",
      "answer": "Entity360 automates the creation of audit templates using GenAI, schedules audits, and ensures that they are conducted efficiently, thus simplifying compliance processes and minimizing risks and costs associated with non-compliance."
    },
    {
      "question": "What training and support does Enkiube Technologies offer for Entity360?",
      "answer": "We provide comprehensive training for all users, personalized support throughout the implementation and use of Entity360, and regular updates and communication to ensure your team maximizes the platform's benefits."
    },
    {
      "question": "Can Entity360 help with digital transformation in my company?",
      "answer": "Absolutely. Entity360 is designed to ease the transition to digital processes, offering tools and features that reduce the reliance on physical documents and streamline workflow management, thereby accelerating your digital transformation journey."
    },
    {
      "question": "What industries can benefit from Entity360?",
      "answer": "While primarily aimed at SME and mid-size manufacturing companies, Entity360 is versatile enough to support various industries seeking to improve workflow management, compliance, and operational efficiency through digital transformation."
    },
    {
      "question": "How is Entity360 priced?",
      "answer": "Entity360 operates on a subscription-based model, offering per-user, per-month licensing that provides a scalable and predictable cost for businesses. We also offer tailored consulting under the CDAP program, which can include a one-year free-use period."
    },
    {
      "question": "How secure is my data with Entity360?",
      "answer": "Data security is a top priority for us. Entity360 employs advanced security measures, including data encryption and regular security audits, to ensure your information is protected and compliant with industry standards."
    },
    {
      "question": "Can Entity360 be customized for my specific business needs?",
      "answer": "Yes, we offer customization options to ensure Entity360 aligns with your unique business processes and requirements, enhancing both the value and impact of our solution on your operations."
    },
    {
      "question": "What are the plans for Entity360 development?",
      "answer": "Our roadmap includes continuous improvement of existing features, integration capabilities with other QMS systems, enhancing our AI models for even more accurate object detection, and developing a gamified platform for increased user engagement."
    },
    {
      "question": "How can I get started with Entity360?",
      "answer": "To get started, contact our sales team for a consultation. We'll discuss your specific needs, provide a demo of Entity360, and guide you through the setup and implementation process to ensure a smooth transition and immediate value from the platform."
    }
  ]

export default questionAnswer