import React, { useRef, useState } from "react";
import { Box } from "@mui/material";

const SquaresComponent = () => {
  const size = 150;

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 150,
          bottom: 0,
          left: { md: 200, lg: 200, xl: 200 },
          borderRadius: "0px",
          bgcolor: "grey.200",
          zIndex: -1,
          height: size,
          width: size,
          transformOrigin: "top left",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 200,
          bottom: 0,
          left: { md: 250, lg: 250, xl: 250 },
          borderRadius: "0px",
          bgcolor: "grey.100",
          zIndex: -1,
          height: size,
          width: size,
          transformOrigin: "top left",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 150,
          bottom: 0,
          left: { md: 500, lg: 500, xl: 500 },
          borderRadius: "0px",
          bgcolor: "grey.200",
          zIndex: -1,
          height: size,
          width: size,
          transformOrigin: "top left",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 200,
          bottom: 0,
          left: { md: 550, lg: 550, xl: 550 },
          borderRadius: "0px",
          bgcolor: "grey.100",
          zIndex: -1,
          height: size * 1.5,
          width: size * 1.5,
          transformOrigin: "top left",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 150,
          bottom: 0,
          left: { md: 900, lg: 900, xl: 900 },
          borderRadius: "0px",
          bgcolor: "grey.200",
          zIndex: -1,
          height: size * 1.5,
          width: size * 1.5,
          transformOrigin: "top left",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 200,
          bottom: 0,
          left: { md: 950, lg: 950, xl: 950 },
          borderRadius: "0px",
          bgcolor: "grey.100",
          zIndex: -1,
          height: size * 2,
          width: size * 2,
          transformOrigin: "top left",
        }}
      />
    </>
  );
};

export default SquaresComponent;
