import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";

const TextImageInfoComponent = ({
  imageToggle,
  topHeader,
  middleHeader,
  text,
  list,
  buttonText,
  buttonGradient,
  buttonLink,
  imageLink,
  haveButton = true,
  topMargin = 4,
  moreText = false,
}) => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate(buttonLink);
  };
  const isImageLeft = imageToggle === "Left";
  const textWidth = moreText ? 8 : 6;
  return (
    <Box sx={{ p: topMargin - 2, my: topMargin }}>
      <Grid className="montserrat" container spacing={10} alignItems="center">
        <Grid
          item
          xs={12}
          md={textWidth}
          order={{ xs: 2, md: isImageLeft ? 1 : 2 }}
        >
          <Box>
            <Typography color="primary" gutterBottom>
              {topHeader}
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
              {middleHeader}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {text}
            </Typography>
            <List>
              {list.map((item, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
            {haveButton && (
              <Button
                onClick={onClickHandler}
                variant="contained"
                sx={{
                  backgroundImage: buttonGradient,
                  ":hover": {
                    // Add hover effect
                    backgroundImage: buttonGradient,
                  },
                }}
                href={buttonLink}
              >
                {buttonText}
              </Button>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12 - textWidth}
          order={{ xs: 1, md: isImageLeft ? 2 : 1 }}
        >
          <img
            src={imageLink}
            alt="Description"
            style={{ width: "100%", height: "auto" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TextImageInfoComponent;
