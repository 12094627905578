import React, { useEffect } from "react";

import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FooterComponent from "../component/footer/FooterComponent";
import HeaderComponent from "../component/header/mainHeaderComponent";
import { getNewsArticles } from "../slices/articlesSlice";

function AppLayout() {
  const dispatch = useDispatch();

  const newsArticles = useSelector((state) => state.articles.newsArticles);

  useEffect(() => {
    dispatch(getNewsArticles());
  }, [dispatch]);
  return (
    <div>
      <HeaderComponent />
      <div>
        <div className="">
          <Outlet />
        </div>
      </div>
      <FooterComponent />
    </div>
  );
}

export default AppLayout;
