import React from "react";

import { Grid, Box, Typography } from "@mui/material";

import BreadCrumbsComponent from "../../../shared/component/breadcrumbs/BreadCrumbComponent";
import TextImageInfoComponent from "../../../shared/component/infoBlocks/textImage";
import MultiTextImageInfoComponent from "../../../shared/component/infoBlocks/multiTextImage";

const FeaturePage = () => {
  const breadcrumbItems = [
    { redirectUrl: "/", text: "Home" },
    { redirectUrl: "/platform", text: "Platform" },
    { redirectUrl: "/platform/features", text: "Features" },
  ];
  return (
    <div>
      <BreadCrumbsComponent items={breadcrumbItems} />
      <Box sx={{}}>
        <Grid
          container
          spacing={0}
          sx={{
            position: "relative",
            py: 0,
            my: 0,
          }}
        >
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={8} sx={{ px: 5 }}>
            <MultiTextImageInfoComponent
              imageToggle="Left"
              topHeader="WHAT WE SEE?"
              middleHeader="Object Detection"
              text={[
                {
                  header: "Purpose",
                  paragraph:
                    "The purpose of our object detection technology is to be able to detect physical entities and associate them with registered entities in our platform. In its common definition, object detection typically involves a supervised learning algorithm trained on a set of labeled data so that they can be detected when appear in an image.  These technologies typically leverage Deep Learning architectures and Convolutional Neural Networks to convert color intensities of an image at pixel level into a collection of edges, shapes, features, and labels.",
                },
                {
                  header: "Limitations",
                  paragraph:
                    "One limitation of these technologies is while they are good at distinguishing between distinctive classes of objects (e.g. human vs. bicycle), they might not work as effectively when dealing with scenarios of mostly similar objects and they are typically at the mercy of their training data accuracy. Imagine a scenario where you have a set-screw of size 5/8” vs. a set-screw of size 1”. How is your trained model supposed to know about their difference?",
                },
              ]}
              haveButton={false}
              imageLink="/snapshot4.png"
              topMargin={2}
            />
          </Grid>
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={12}>
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
              textAlign="center"
              sx={{ my: 5 }}
            >
              What makes our Technology Better?{" "}
            </Typography>
          </Grid>
          <Grid
            item
            xs={0}
            md={3}
            sx={{ background: "#eeeeee", my: { xs: 5, sm: 5, md: 5 } }}
          />
          <Grid
            item
            xs={12}
            md={7}
            sx={{ background: "#eeeeee", my: { xs: 5, sm: 5, md: 5 } }}
          >
            <TextImageInfoComponent
              imageToggle="Right"
              topHeader="WE SEE DEPTH"
              middleHeader="Multi-Layer Object Detection"
              text="While we leverage similar concept in our object detection technologies, we have enhanced it by developing a multi-layer prediction by incorporating:"
              list={[
                "Monocular Depth Estimation (MDE): to accommodate for the depth of the trained objects. This approach significantly improves the accuracy of our object detection algorithm when dealing with almost similar objects that vary in dimension.",
                "Optical Character Recognition (OCR) technologies: This is to gain zero-shot insight about the object if there is any text available in the evaluated image such as engraving on the part or labels",
                "Hierarchical Consolidation of inference: Our QA Co-pilot allows us to develop a thorough knowledge graph on physical entities. This significantly helps us to consolidate inference results.",
              ]}
              haveButton={false}
              imageLink="/snapshot5.png"
              moreText={true}
            />
          </Grid>
          <Grid
            item
            xs={0}
            md={2}
            sx={{ background: "#eeeeee", my: { xs: 5, sm: 5, md: 5 } }}
          />
          {/* DOCUMENT AI */}
          <Grid item xs={0} md={3} sx={{ mt: { xs: 2, sm: 2, md: 2 } }} />
          <Grid item xs={12} md={6} sx={{ mt: { xs: 2, sm: 2, md: 2 }, p: 2 }}>
            <MultiTextImageInfoComponent
              imageToggle="Left"
              topHeader="HOW DO WE READ?"
              middleHeader="Document AI"
              text={[
                {
                  header: "",
                  paragraph:
                    "One of the main challenges of Digital Transformation for conventional operations is the lack of access to documents such as work instructions, TWIs, SOPs, and standard documents in their digital workflows. With accelerating growth in text, vision, and multimodal Artificial intelligence, our team has made this transition as seamless as possible.",
                },
                {
                  header: "",
                  paragraph:
                    "Our fine-tuned DocumentAI models enable us to ingest your scanned documents into structured data and make information about document context, summary, and layout, structured and accessible for system-level decision-making. We leverage technologies such as Optical Character Retrieval, Document Visual Question Answering models, Large Language Model Embeddings, Retrieval Augmented Generation frameworks, and Document Layout Detection algorithms to gain an advanced level of understanding of documents not only at the single document level but also at the collection of documents level.",
                },
              ]}
              haveButton={false}
              imageLink="/snapshot6.png"
              topMargin={2}
              moreText={true}
            />
          </Grid>
          <Grid item xs={0} md={3} sx={{ mt: { xs: 2, sm: 2, md: 2 } }} />

          {/* Generative AI Description */}
          <Grid
            item
            xs={0}
            md={3}
            sx={{ background: "#eeeeee", my: { xs: 5, sm: 5, md: 5 } }}
          />
          <Grid
            item
            xs={12}
            md={7}
            sx={{ background: "#eeeeee", my: { xs: 5, sm: 5, md: 5 }, px: 5 }}
          >
            <TextImageInfoComponent
              imageToggle="Right"
              topHeader="WE SEE PROCESS"
              middleHeader="Multi-Modal Understanding"
              text="While Multi-modal understanding is commonly referred to next generation of Generative AI models with the ability to receive input modalities other than just text (such as Image, video, etc.), we refer to multi-modal understanding more in the context of our AI architecture. You can think about our architecture such that information (inputs) from different modalities is received and transformed into structured text or vector information that is consumable by other models. For instance, in the case of a recorded meeting, we leverage a combination of commercial speech-to-text technologies and Machine Learning algorithms to diarize communication in text format."
              list={[]}
              haveButton={false}
              imageLink="/snapshot7.svg"
              moreText={false}
            />
          </Grid>
          <Grid item xs={0} md={2} sx={{ my: { xs: 5, sm: 5, md: 5 } }} />
        </Grid>
      </Box>
    </div>
  );
};

export default FeaturePage;
