import React from "react";

import { Grid, Box } from "@mui/material";
import HeroMessageComponent from "../../../shared/component/heroMessage";
import InfoOnlyTextWithIconComponent from "../../../shared/component/infoBlocks/onlyTextWithIcon";

import CardComponent from "../../../shared/component/CardComponent";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import TeamComponent from "../components/TeamComponent";

import NewsCarouselComponent from "../components/CarouselComponent";

import BreadCrumbsComponent from "../../../shared/component/breadcrumbs/BreadCrumbComponent";
const breadcrumbItems = [
  { redirectUrl: "/", text: "Home" },
  { redirectUrl: "/company", text: "Company" },
  { redirectUrl: "/company/teams", text: "Teams" },
];

const WhoWeArePage = () => {
  return (
    <div>
      <Box
        sx={{
          flexGrow: 1,
          height: { xs: "50vh", sm: "40vh", md: "30vh", lg: "30vh" },
          position: "relative",
        }}
      >
        {/* Overlay with tint */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: { xs: "50vh", sm: "40vh", md: "30vh", lg: "30vh" },
            background: "linear-gradient(45deg, #213765 30%, #1F4963 90%)",
          }}
        />
        <HeroMessageComponent message="Empowering Innovation, Enhancing Efficiency" />
      </Box>
      <BreadCrumbsComponent items={breadcrumbItems} />
      <Box
        sx={{
          position: "relative",
          backgroundColor: "transparent",
          "&:before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "30%",
            bgcolor: "#EFEFFF", // Use your theme's primary color
            transform: "skewY(-10deg)", // Skew angle for the diagonal
            transformOrigin: "right",
            zIndex: -1, // Ensure the ribbon is behind the content
          },
        }}
      >
        <Grid
          container
          spacing={10}
          sx={{
            position: "relative",
            py: { xs: 0, sm: 5, md: 5, lg: 0 },
            px: { xs: 2, sm: 0, md: 0, lg: 0 },
          }}
        >
          <Grid item xs={0} sm={0} md={2} lg={1} xl={2} />
          <Grid item xs={12} sm={12} md={4} lg={5} xl={4}>
            <InfoOnlyTextWithIconComponent
              topHeader="ESTABLISHED IN 2022"
              middleHeader="We are Enkiube Technologies"
              text="Learn about the tireless team behind the scene to make our magic happen!"
              IconComponent={SupervisedUserCircleIcon}
              color="#18A0FB"
            />
            <TeamComponent />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={5} xl={4}>
            <CardComponent
              imageSrc="https://www.utm.utoronto.ca/imi/sites/files/imi/styles/full_width_m/public/2023-02/IMI%20complex.JPG.webp?itok=s-G7A-ph"
              imageWidthPercentage={100}
              imagePadding="0px"
              header="How we Started?"
              text="<div>Enkiube Technologies is a Canadian software solution provider started in 2022 through the University of Toronto’s ICUBE UTM incubator. </div><div>Our inception was driven by a keen observation of the fundamental challenges industries face regarding process improvement and quality assurance. We are committed to leveraging digital innovation to incorporate Poka-Yoke principles, thereby error-proofing processes and enhancing efficiency.</div><div>We were founded with a mission to help SMEs revolutionize their approach to digital transformation and eliminate their hidden costs through innovative, AI-driven solutions.  While we work closely with our customers to develop customized solutions for their operational needs, our solutions are incorporated through Entity360 our proprietary processAI platform where all of our services our accessible under a unified platform.</div>"
              textAlign="left"
            />
          </Grid>
          <Grid item xs={0} sm={0} md={2} lg={1} xl={2}></Grid>
        </Grid>
        <Grid
          container
          sx={{
            backgroundColor: "lightgrey",
            padding: "20px",
            my: { xs: 3, md: 4 },
          }}
        >
          <Grid item xs={0} md={2}></Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <NewsCarouselComponent />
          </Grid>
          <Grid item xs={0} md={2}></Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default WhoWeArePage;
