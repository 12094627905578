import React, { useState, useEffect } from "react";
import { Grid, Typography, Select, MenuItem } from "@mui/material";
import NewsCardComponent from "./NewsCardComponent";

const AllNewsComponent = ({ newsItems }) => {
  const [news, setNews] = useState(newsItems);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    let sortedAndFilteredNews = [...newsItems];
    if (filter) {
      sortedAndFilteredNews = sortedAndFilteredNews.filter((item) =>
        item.tags.some((tag) => tag.tagName.includes(filter))
      );
    }
    sortedAndFilteredNews.sort((a, b) => {
      const dateA = new Date(a.date); // Assuming 'date' is the field name
      const dateB = new Date(b.date);
      return dateB - dateA;
    });
    setNews(sortedAndFilteredNews);
  }, [filter, newsItems]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <div>
      <Grid container sx={{ mb: 10 }}>
        <Grid item xs={12} sm={6} md={9}>
          <Typography variant="h6" gutterBottom>
            Latest news about us...
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" textAlign={"center"}>
            <span>Select Tag: </span>
            <Select
              value={filter}
              onChange={handleFilterChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ p: 0, width: "150px", height: "40px", mx: 2 }}
            >
              <MenuItem value="">
                <em>All Tags</em>
              </MenuItem>
              {/* Improved approach for listing all unique tagNames */}
              {[
                ...new Set(
                  newsItems.flatMap((newsItem) =>
                    newsItem.tags.map((tag) => tag.tagName)
                  )
                ),
              ]
                .sort() // Optional: Sort tags alphabetically
                .map((tagName) => (
                  <MenuItem key={tagName} value={tagName}>
                    {tagName}
                  </MenuItem>
                ))}
            </Select>
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid container spacing={2}>
          {news.map((newsItem) => (
            <Grid item xs={12} sm={6} md={4} key={newsItem.slug}>
              <NewsCardComponent newsItem={newsItem} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default AllNewsComponent;
