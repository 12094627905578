import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Link,
  Box,
} from "@mui/material";

const BusinessGrantCard = ({ image, header, text, hyperlinkText, url }) => {
  return (
    <Card sx={{ maxWidth: 345, borderRadius: 2, boxShadow: 3 }}>
      <CardMedia
        component="img"
        image={image}
        alt={header}
        sx={{ height: 140, objectFit: "cover" }}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {header}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {text}
        </Typography>
        <Box mt={2}>
          <Link href={url} underline="hover" color="primary">
            {hyperlinkText} <span aria-hidden="true">↗</span>
          </Link>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BusinessGrantCard;
