import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { 
    getNewsArticlesService
} from "../services/getNewsArticles";


 export const getNewsArticles = createAsyncThunk('articles/getNewsArticles', async() => {
    try {
        const response = await getNewsArticlesService();
        return response; // or repsonse.data
    } catch (error) {
        console.log(error);
        throw error; // Allows the thunk to recognize and handle the action as rejected
    }
});


const initialState = {
    isSubmitting: false,    
    newsArticles: []
}


const articlesSlice = createSlice({
    name: "articles",
    initialState,
    reducers: {
        setNewsArticles: (state,action) => {
            state.newsArticles[action.payload.name] = action.payload.value;
        }        
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNewsArticles.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getNewsArticles.fulfilled, (state, action) => {
                state.isLoading = false;
                state.newsArticles = action.payload;
            })
            .addCase(getNewsArticles.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },         
})

export const {setNewsArticles}=articlesSlice.actions;
export default articlesSlice.reducer;