import React, { useRef, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import BreadCrumbsComponent from "../../../shared/component/breadcrumbs/BreadCrumbComponent";
import SquaresComponent from "../../../shared/component/backgrounds/SquaresComponent";
import { submitMainForm } from "../../../shared/slices/submitFormsSlice";
import { useDispatch } from "react-redux";

const breadcrumbItems = [
  { redirectUrl: "/", text: "Home" },
  { redirectUrl: "/contact-us", text: "Contact us" },
];

const ContactUsPage = () => {
  const dispatch = useDispatch();
  const [captcha, setCaptcha] = useState("");
  const [form, setForm] = useState({});
  const [formMessage, setFormMessage] = useState("");
  const captchaRef = useRef(null);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateGeneral = (form) => {
    if (!form["name"] || !form["company"]) {
      return false;
    }

    return true;
  };

  // Form submission handler
  const onFormSubmitHandler = (e) => {
    e.preventDefault();

    if (validateEmail(form["email"])) {
      if (validateGeneral(form)) {
        dispatch(submitMainForm(form));
        setFormMessage("Email sent succesfully");
      } else {
        setFormMessage("Please fill out all required fields");
      }
    } else {
      setFormMessage("Invalid Email Provided");
    }
  };

  // ReCAPTCHA change handler
  const handleReCAPTCHAChange = (value) => {
    // Handle the change of ReCAPTCHA value here
  };

  // Layout styles
  const formContainerStyle = {
    backgroundColor: "rgba(255,255,255,0.4)", // Change this to match the form's background color
    padding: "2rem",
    marginBottom: "5rem",

    borderRadius: "3px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)", // Adjust shadow to match your design
  };

  const inputStyle = {
    marginBottom: "1rem",
  };
  const onFormChangeHandler = (e) => {
    const { name, value } = e.target;

    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  return (
    <Box>
      <BreadCrumbsComponent items={breadcrumbItems} />
      <SquaresComponent />
      <Container maxWidth="md" style={formContainerStyle}>
        <Typography variant="h4" gutterBottom textAlign="center">
          Contact us to Evaluate
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          textAlign="center"
          sx={{ mb: 10 }}
        >
          We are always happy to hear from you! If you have any questions,
          concerns, or feedback, please don't hesitate to reach out. Our team
          will do their best to get back to you as soon as possible.
        </Typography>
        <form onSubmit={onFormSubmitHandler}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                required
                label="Name"
                id="name"
                name="name"
                variant="outlined"
                onChange={onFormChangeHandler}
                style={inputStyle}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                label="Company Name"
                id="company"
                name="company"
                variant="outlined"
                onChange={onFormChangeHandler}
                style={inputStyle}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                fullWidth
                required
                label="Email address"
                id="email"
                name="email"
                variant="outlined"
                onChange={onFormChangeHandler}
                style={inputStyle}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                fullWidth
                label="Type of Inquiry"
                id="inquiryOption"
                name="inquiryOption"
                variant="outlined"
                select
                SelectProps={{ native: true }}
                style={inputStyle}
              >
                <option value="">None</option>
                <option value="Entity360">Entity360</option>
                <option value="CDAP & Consulting Service">
                  CDAP & Consulting Service
                </option>
                <option value="Partnership">Partnership</option>
                <option value="General Inquiry">General Inquiry</option>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                fullWidth
                label="Message"
                id="message"
                name="message"
                variant="outlined"
                multiline
                onChange={onFormChangeHandler}
                rows={4}
                style={inputStyle}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {/* Assuming you have set up Google ReCAPTCHA for your site */}
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                onChange={(response) => setCaptcha(response)}
                ref={captchaRef}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                type="submit"
                disabled={!captcha}
                variant="contained"
                color="primary"
                sx={{ my: 3, width: 200 }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        <iframe
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=3359%20Mississauga%20Rd,%20Mississauga,%20ON%20L5L%201C6+(Enkiube%20Technologies)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          title="map"
          scrolling="no"
          frameBorder="0"
          width="100%"
          height="450"
          className=""
          loading="lazy"
        />
      </Container>
    </Box>
  );
};

export default ContactUsPage;
