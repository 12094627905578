import { React, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  Link,
  InputAdornment,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useDispatch } from "react-redux";
import LogoVerticalComponent from "../LogoVerticalComponent";
import { submitMainForm } from "../../slices/submitFormsSlice";

const FooterLinkColumn = ({ title, links }) => (
  <Grid item xs={6} sm={6} md={2}>
    <Typography variant="h6" color="#FFFFFF" gutterBottom>
      {title}
    </Typography>
    {links.map((link, index) => (
      <Box key={index} mb={1}>
        <Link href={link.url} variant="subtitle1" color="#FFFFFF">
          {link.label}
        </Link>
      </Box>
    ))}
  </Grid>
);

const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

const FooterComponent = () => {
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState("");
  const [form, setForm] = useState({
    name: "",
    company: "",
    email: "",
    inquiryOption: "NewsLetter",
    message: "Footer",
  });

  const onFormChangeHandler = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onFormSubmitHandler = (e) => {
    e.preventDefault();
    if (validateEmail(form.email)) {
      dispatch(submitMainForm(form));
      setFormMessage("Email sent successfully");
    } else {
      setFormMessage(
        "Please fill out all required fields and provide a valid email"
      );
    }
  };

  const footerLinks = {
    company: [
      { label: "Mission, Vision, Values", url: "/company" },
      { label: "Who are we?", url: "/company/team" },
      { label: "News", url: "/company/news" },
    ],
    platform: [
      { label: "What is Entity360?", url: "/platform" },
      { label: "Features", url: "/platform/features" },
    ],
    solutions: [
      { label: "Manufacturing", url: "/solutions/manufacturing" },
      { label: "Regulatory & Compliance", url: "/solutions/auditors" },
      { label: "CDAP Program", url: "/solutions/cdap" },
    ],
    resourceCenter: [
      { label: "FAQs", url: "/resources/faqs" },
      { label: "Privacy Policies", url: "/resources/privacy" },
      { label: "Terms and Conditions", url: "/resources/terms" },
    ],
  };

  return (
    <Box bgcolor="#1F4963" color="common.white" py={6}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ textAlign: { xs: "center", sm: "center" } }}
          >
            <Typography variant="h5" color="#FFFFFF" gutterBottom>
              Stay In Touch
            </Typography>
            <Typography variant="subtitle1" color="#ffffff" gutterBottom>
              Please subscribe to our newsletter so we can send you frequent
              updates for our success stories and new services.
            </Typography>
          </Grid>
          <Grid item xs={0} sm={0} md={4}></Grid>
          <Grid item xs={0} sm={12} md={4}>
            <TextField
              id="email"
              name="email"
              variant="outlined"
              onChange={onFormChangeHandler}
              placeholder="Email"
              fullWidth
              margin="normal"
              sx={{
                backgroundColor: "#FFFFFF",
                color: "#AAAAAA",
                borderRadius: 1,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon style={{ fill: "#AAAAAA" }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={onFormSubmitHandler}
              fullWidth
              sx={{
                backgroundColor: "#2D2E80",
                "&:hover": {
                  backgroundColor: "#9A9CF2",
                },
              }}
            >
              SUBSCRIBE
            </Button>
          </Grid>
          <Grid item xs={0} sm={0} md={4}></Grid>
          <Grid item xs={0} sm={0} md={1}></Grid>
          <Grid item xs={12} sm={6} md={2}>
            <LogoVerticalComponent xs="none" md="flex" />
          </Grid>
          <FooterLinkColumn title="Company" links={footerLinks.company} />
          <FooterLinkColumn title="Platform" links={footerLinks.platform} />
          <FooterLinkColumn title="Solutions" links={footerLinks.solutions} />
          <FooterLinkColumn
            title="Resource Center"
            links={footerLinks.resourceCenter}
          />
          <Grid item xs={0} sm={0} md={1}></Grid>
        </Grid>
        <Box mt={3} borderTop={1} borderColor="grey.500" pt={3}>
          <Typography variant="body2" color="#ffffff" align="center">
            © 2024 Enkiube Technologies, Inc.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default FooterComponent;
